import { RouteRecordRaw } from 'vue-router'

export default [
  /**
   * @note The following routes are non-authenticated (not requiring a session token) and will
   *       be visible to the public at all times. This will allow us to prevent weird routing
   *       issues and other things that could occur.
   */
  {
    name: 'account',
    path: '/account',
    component: () => import('@/modules/account/Container.vue'),
    children: [
      // Redirect old login pages to dashboard (which redirects to web for login then back to dashboard)
      {
        path: 'login',
        name: 'login',
        redirect: { name: 'home' }
      },
      {
        path: 'recover',
        name: 'recover',
        redirect: { name: 'home' }
      },
      {
        path: 'reset/:token',
        name: 'reset',
        redirect: { name: 'home' }
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('@/modules/account/views/Terms.vue'),
        meta: {
          public: true,
          title: 'Accept Terms'
        }
      },
      {
        path: 'activate/:token',
        name: 'activate',
        component: () => import('@/modules/account/views/Activate.vue'),
        meta: {
          title: 'Activate Account'
        }
      },
      {
        path: 'unsupported-browser',
        name: 'unsupported-browser',
        component: () => import('@/modules/account/views/UnsupportedBrowser.vue'),
        meta: {
          title: 'Unsupported Browser'
        }
      },
      {
        path: '/status',
        name: 'status',
        component: () => import('@/modules/common/views/status.vue'),
        meta: {
          title: 'Status'
        }
      }
    ]
  },

  /**
   * @note The following routes are authenticated and require a valid account with the correct
   *       permissions in order to access this information.
   */
  {
    path: '/',
    component: () => import('@/modules/application/Container.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/modules/deck/views/DeckList.vue'),
        meta: {
          title: 'Decks'
        }
      },
      {
        path: '/dashboard',
        name: 'company--dashboard',
        component: () => import('@/modules/company/views/Dashboard.vue'),
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/my-company',
        name: 'company--detail',
        component: () => import('@/modules/company/views/CompanyDetail.vue'),
        meta: {
          title: 'My Company'
        }
      },
      {
        path: 'companies',
        name: 'company--list',
        component: () => import('@/modules/company/views/List.vue'),
        meta: {
          title: 'Switch Company'
        }
      },
      {
        path: 'billing',
        name: 'company--billing',
        component: () => import('@/modules/company/views/Billing.vue'),
        meta: {
          title: 'Billing'
        }
      },

      //
      // decks
      //

      {
        path: 'decks',
        name: 'deck--list',
        component: () => import('@/modules/deck/views/DeckList.vue'),
        meta: {
          title: 'Decks'
        }
      },
      {
        path: '/decks/:deckId',
        name: 'deck--editor',
        component: () => import('@/modules/deck/views/DeckEditor.vue'),
        meta: {
          title: 'Deck Editor'
        }
      },

      //
      // video recordings
      //

      {
        path: 'video-recordings',
        name: 'video--list',
        component: () => import('@/modules/video/views/VideoList.vue'),
        meta: {
          title: 'Video Recordings'
        }
      },
      {
        path: '/video-recordings/:recordingId',
        name: 'recording--editor',
        component: () => import('@/modules/video/views/VideoEditor.vue'),
        meta: {
          title: 'Recording Editor'
        }
      },

      //
      // projects
      //

      {
        path: 'projects',
        name: 'project--list',
        component: () => import('@/modules/project/views/ProjectList.vue'),
        meta: {
          title: 'Projects'
        }
      },
      {
        path: '/projects/:projectId/sites',
        name: 'site--list',
        component: () => import('@/modules/site/views/List.vue'),
        meta: {
          title: 'Sites'
        }
      },
      {
        path: '/projects/:projectId/sites/:siteId',
        name: 'site--editor',
        component: () => import('@/modules/site/views/Editor.vue'),
        meta: {
          title: 'Site Editor'
        }
      },

      //
      // inbox
      //

      {
        path: '/inbox',
        name: 'inbox--list',
        component: () => import('@/modules/inbox/Container.vue'),
        meta: {
          title: 'Inbox'
        },
        children: [
          {
            path: 'question/:questionId?',
            alias: '',
            name: 'inbox--detail',
            component: () => import('@/modules/inbox/views/Detail.vue'),
            meta: {
              title: 'Question'
            }
          }
        ]
      },

      //
      // shelf
      //

      {
        path: '/files/:folderId?',
        name: 'shelf--list',
        component: () => import('@/modules/shelf/views/ShelfList.vue'),
        meta: {
          title: 'File Browser'
        }
      },

      //
      // press
      //

      {
        path: 'press-releases',
        name: 'press-release--list',
        component: () => import('@/modules/press/views/PressList.vue'),
        meta: {
          title: 'Press Releases'
        }
      },
      {
        path: 'press-releases/editor/:articleId?',
        name: 'press-release--editor',
        component: () => import('@/modules/press/views/Editor.vue'),
        meta: {
          title: 'Press Releases Detail'
        }
      },

      //
      // embeds
      //

      {
        path: 'embeds',
        name: 'embeds--list',
        component: () => import('@/modules/embed/views/EmbedList.vue'),
        meta: {
          title: 'Embeds'
        }
      },
      {
        path: 'embed/animated/:embedId?',
        name: 'embed--animated--detail',
        component: () => import('@/modules/embed/views/DeckEmbedDetail.vue'),
        meta: {
          title: 'Animated Embed Detail'
        }
      },
      {
        path: 'embed/animated/editor/:embedId?',
        name: 'embed--animated--editor',
        component: () => import('@/modules/embed/views/DeckEmbedEditor.vue'),
        meta: {
          title: 'Animated Embed Editor'
        }
      },
      {
        path: 'embed/classic/:embedId?',
        name: 'embed--classic--detail',
        component: () => import('@/modules/embed/views/DeckEmbedDetail.vue'),
        meta: {
          title: 'Classic Embed Detail'
        }
      },
      {
        path: 'embed/classic/editor/:embedId?',
        name: 'embed--classic--editor',
        component: () => import('@/modules/embed/classic/views/ClassicDeckEmbedEditor.vue'),
        meta: {
          title: 'Classic Embed Detail'
        }
      },
      {
        path: 'embed/video/:embedId?',
        name: 'embed--video--detail',
        component: () => import('@/modules/embed/video/views/VideoEmbedDetail.vue'),
        meta: {
          title: 'Video Embed Detail'
        }
      },
      {
        path: 'embed/video/editor/:embedId?',
        name: 'embed--video--editor',
        component: () => import('@/modules/embed/video/views/VideoEmbedEditor.vue'),
        meta: {
          title: 'Video Embed Editor'
        }
      },

      //
      // users
      //

      {
        path: 'users',
        name: 'user--list',
        component: () => import('@/modules/user/views/UserList.vue'),
        meta: {
          title: 'Users'
        }
      },
      {
        path: 'users/:userId',
        name: 'user--detail',
        component: () => import('@/modules/user/views/Detail.vue'),
        meta: {
          title: 'User Detail'
        }
      },

      //
      // models
      //

      {
        path: 'models',
        name: 'model--list',
        component: () => import('@/modules/model/views/ModelList.vue'),
        meta: {
          title: '3D Models'
        }
      },

      //
      // assets
      //

      {
        path: 'assets',
        name: 'asset--list',
        component: () => import('@/modules/asset/views/AssetList.vue'),
        meta: {
          title: 'Assets'
        }
      },
      {
        path: 'assets/editor/:assetId?',
        name: 'asset--editor',
        component: () => import('@/modules/asset/views/Editor.vue'),
        meta: {
          title: 'Assets Detail'
        }
      },
      {
        path: 'assets/:assetId?/files',
        name: 'asset--files',
        component: () => import('@/modules/asset/views/Files.vue'),
        meta: {
          title: 'Assets Detail Files'
        }
      },

      //
      // data files
      //
      {
        path: 'data-files',
        name: 'data-files--list',
        component: () => import('@/modules/data-files/views/DataFileList.vue')
      },

      //
      // dora
      //
      {
        path: 'prediction-maps',
        name: 'prediction-maps--list',
        component: () => import('@/modules/experiments/views/ExperimentsList.vue')
      }
    ]
  },

  /**
   * Set the unauthorized page to the 403 page so that we can catch any attempt
   * to view an unauthorized resource.
   */
  {
    path: '/not-authorized',
    name: 'error--403',
    component: () => import('@/modules/common/views/403.vue'),
    meta: {
      title: 'Not Authorized'
    }
  },

  /**
   * Set the not found to the 404 page so that we can catch any attempt
   * to view an unauthorized resource.
   */
  {
    path: '/not-found',
    name: 'error--404',
    component: () => import('@/modules/common/views/404.vue'),
    meta: {
      title: 'Page Not Found'
    }
  },

  /**
   * Maintenance page
   */
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/modules/common/views/maintenance.vue'),
    meta: {
      title: 'Maintenance'
    }
  },

  /**
   * Set the redirect for all routes to the 404 page so that we can catch any attempt to
   * try and url hack. Eventually we might want to give more information or something more
   * user friendly.
   */
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'error--404' }
  }
] as RouteRecordRaw[]
