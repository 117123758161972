import { App } from 'vue'
import router from '@/modules/application/router'

/**
 * This plugin will enable the router functionality for the application, and will
 * allow us to initialize it with the container application. This will act as a composable
 * etc.
 */
export default function useRouter(app: App): App {
  return app.use(router)
}
