import RestService from '@/modules/api/rest-service'
import type { Folder } from '@/modules/api/types/shelf'
// stores & services
import CompanyStore from '@/modules/api/stores/company'

export interface FolderResults {
  count: number
  next: string | null
  previous: string | null
  results: Folder[]
}

export default new (class FolderRepository extends RestService<Folder> {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    if (!CompanyStore.$state.selected) {
      return ''
    }

    return `/companies/${CompanyStore.$state.selected.id}/shelf/folders`
  }

  /**
   * Since there is no direct way to find a folder by its folder name, we need to load
   * all folders and filter (there are very small amounts).
   *
   * @param folderName {string} - the folder to find.
   * @oaram isRootLevel {boolean} - whether to search only root level folders.
   * @return {Readonly<Folder> | undefined} - the folder(s) found or undefined.
   */
  public async findByName(folderName: string, isRootLevel = false): Promise<Readonly<Folder> | undefined> {
    const folders = (await this.query()).results

    return folders.find((folder: Readonly<Folder>) => {
      const nameMatches = folder.name?.toLowerCase().includes(folderName.toLowerCase().replace("'", ''))

      return isRootLevel ? nameMatches && !folder.parent_id : nameMatches
    })
  }
})()
