import { ref } from 'vue'
import copy from 'clipboard-copy'
import useNotifications, { NotificationTheme, Notification } from '@/packages/notification'

type Params = {
  notification: Pick<Notification, 'title' | 'message'>
  onCodeCopied: () => void
}

export default function useCopyCode({ notification, onCodeCopied }: Params) {
  const { notify } = useNotifications()
  const copied = ref(false)

  async function copyCode(textContent: string): Promise<void> {
    await copy(textContent)

    // Issue a toast notification indication success. We are guarding against
    // spamming toast notifications through the copy button by using a simple
    // variable in the state. This is similar to a debounce, but doesn't cause
    // wacky behaviour.
    if (copied.value) return

    copied.value = true

    const toastTimeout = 1000 * 4
    notify({
      ...notification,
      theme: NotificationTheme.Success,
      timeout: toastTimeout
    })

    // Set the timeout to the same amount of time as the default toast notification.
    setTimeout(() => {
      copied.value = false
    }, toastTimeout)

    onCodeCopied()
  }

  return { copied, copyCode }
}
