import { Axios } from '@/modules/api/axios'
import { Model } from '@/modules/api/types'

export default new (class FeatureFlagService extends Axios {
  /**
   * Return the feature flags associated to the current logged in users account. The flags
   * will be determined based on the session token provided internally on the request.
   *
   * @return {Promise<void>}
   */
  public async query(): Promise<Model[]> {
    return (await this.$axios.get('/flags')).data
  }
})()
