import * as Sentry from '@sentry/browser'
import { createAxiosInstance } from '@/modules/api/axios.utilities'
import SessionStore from '@/modules/api/stores/session'
import { loginWithRedirect } from '@/modules/application/router/router.utilities'

const instance = createAxiosInstance()

// Add in the header properties required in order to make authenticated
// requests. If this is not in place, than any protected request will be
// rejected.
instance.interceptors.request.use(
  async (request: any) => {
    // Check the expiration of the session, and issue a refresh if required.
    if (!request.url.includes('/auth') && !request.url.includes('invitation')) {
      await SessionStore.refresh()
    }

    // Check if we got an access token from the refresh call, otherwise
    // just assume the token is null which will force login.
    if (SessionStore.$state.accessToken) {
      // eslint-disable-next-line
      request.headers.Authorization = `Bearer ${SessionStore.$state.accessToken}`
    }

    return request
  },
  (err: any) => Promise.reject(err)
)

// Add a response interceptor for handling authentication errors
instance.interceptors.response.use(
  // eslint-disable-next-line
  (response: any) => {
    return response
  },
  async (error: any) => {
    // If the response from the api is unauthorized then we need to go ahead
    // and throw the user back to the login screen.
    if (error.response.status === 401) {
      loginWithRedirect()
    }

    // Capture any errors that are possible, and throw them to sentry for tracking.
    Sentry.captureException(error)
    // Reject and bubble up.
    return Promise.reject(error)
  }
)

/** Export the axios instance just in case we want to access it. */
export default instance

/**
 * Export the abstract Axios class that all service methods should abstract as
 * an implementation. This will allow us to provide api interaction easily.
 */
export abstract class Axios {
  // Rather than importing axios instances directly into services
  // we need to wrap the axios instances in an internal variable so that
  // we can access them in internal methods.
  protected $axios = instance
}
