// import libraries
import { App, createApp } from 'vue'
import { DateTime } from 'luxon'
import { MultiDrag, Sortable } from 'sortablejs'
import { createGtm } from '@gtm-support/vue-gtm'
// import router & plugins
import useRouter from '@/modules/application/router/plugin'
import useHeroIcons from '@/modules/application/plugins/heroicons'
import useHeadlessUI from '@/modules/application/plugins/headless-ui'
import useCommonComponents from '@/modules/application/plugins/common-components'
import useSentry from '@/modules/application/plugins/sentry'
import useIntercom from '@/modules/application/plugins/intercom'
import useImageCropper from '@/modules/application/plugins/image-cropper'
// package plugins
import useForms from '@/packages/form'
// import directives
import useRoleDirective from '@/modules/application/directives/role'
import usePermissionDirective from '@/modules/application/directives/permission'
import useCompanyFeatureDirective from '@/modules/application/directives/company-feature'
import useUserFeatureDirective from '@/modules/application/directives/user-feature'
// import types
import { Model } from '@/modules/api/types'
import { Permission, Role, Feature } from '@/modules/api/enums'
// import styles
import './styles/tailwind.scss'
import 'vue-advanced-cropper/dist/style.css'
// import application container
import ApplicationContainer from './App.vue'

// Create the application instance
const app: App = createApp(ApplicationContainer)

// Google Tag Manager
if ('VUE_APP_GTM_ID' in process.env) {
  app.use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID as string,
      enabled: true,
      debug: false
    })
  )
}

/**
 * @note We wrap the bootstrap in the intercom plugin only due to the app having to boot
 *       *after* the intercom does, so that we can access window methods.
 */
useIntercom(app).then((app: App) => {
  // Mount the sortable plugins
  // Make sure that multidrag is only mounted once to prevent
  Sortable.mount(new MultiDrag())

  // Apply the plugins
  useRouter(app)
  useCommonComponents(app)
  useHeroIcons(app)
  useHeadlessUI(app)
  useSentry(app)
  useImageCropper(app)

  // Packages
  useForms(app)

  // Apply the directives
  useRoleDirective(app)
  usePermissionDirective(app)
  useCompanyFeatureDirective(app)
  useUserFeatureDirective(app)

  // Mount the application
  app.mount('#app')

  // Suppress warnings since they are not useful. Note that warnings are not
  // the same as errors and errors will still be displayed.
  app.config.warnHandler = () => null

  // Add in various global properties
  app.config.globalProperties.$env = process.env
  app.config.globalProperties.$role = Role
  app.config.globalProperties.$permission = Permission
  app.config.globalProperties.$feature = Feature

  // Add in various filters in the global space
  app.config.globalProperties.$filters = {
    /**
     * Returns the value formatted into a date string by the moment library.
     *
     * @param value {string} - the value passed by the view.
     * @param format {string} - the format to return.
     * @return {string}
     */
    date(value: string, format = 'ff'): string {
      return value ? DateTime.fromISO(value).toFormat(format) : ''
    },

    /**
     * Returns a specifically formatted relative date based on "apple time", and will follow
     * the business logic:
     *  1. If same day -> HH:MM
     *  2. 1-7 days ago -> Day Name
     *  3. 7+ days ago -> YYYY-MM-DD
     * @param value
     */
    relativeDate(value: string): string {
      // If no date value is provided then we want to return a blank.
      if (!value) {
        return ''
      }

      const dt = DateTime.fromISO(value)
      const startOfDay = new DateTime(DateTime.now()).startOf('day')
      const weekAgo = new DateTime(DateTime.now()).minus({ days: 7 }).startOf('day')

      // 7+ days ago -> YYYY-MM-DD
      if (dt < weekAgo) {
        return dt.toISODate()
      }

      // 1-7 days ago -> Day Name
      if (dt < startOfDay) {
        return dt.toFormat('EEEE')
      }

      // Same day -> HH:MM
      return dt.toFormat('h:mm a')
    },

    /**
     * Returns the value formatted into a common currency string.
     *
     * @param value {any} - the value to format.
     * @return {string}
     */
    currency(value: any): string {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })

      return value ? formatter.format(value) : '0'
    },

    /**
     * Returns the market cap value formatted to specified values.
     *
     * @param value {any} - the value to format.
     * @return {string}
     */
    marketCap(value: any): string {
      return `${Math.round(value).toLocaleString()}M`
    },

    /**
     * Returns the value formatted into a common percentage string.
     *
     * @param value {any} - the value to format.
     * @return {string}
     */
    percentage(value: any): string {
      return value ? `(${Math.round(value * 100) / 100}%)` : '0.00%'
    },

    /**
     * Returns the role object formatted into a correct string for ui display.
     *
     * @param role {Model} - the role record.
     * @return {string}
     */
    role(role: Model): string {
      if (role === null) {
        return ''
      }

      switch (role.key.toLowerCase()) {
        case 'basic':
          return 'User'
        case 'observer':
          return 'Special Access'
        default:
          return role.key.replace('_', ' ')
      }
    },

    /**
     * Returns a formatted file size object for the passed value.
     *
     * @param size {number} - the size in bytes for a file
     * @return {string}
     */
    fileSize(size: number): string {
      if (size === 0) return '0 Bytes'

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(size) / Math.log(1024))

      return `${parseFloat((size / 1024 ** i).toFixed(2))} ${sizes[i]}`
    }
  }
})
