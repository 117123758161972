import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyCode = _resolveComponent("CopyCode")!

  return (_openBlock(), _createBlock(_component_CopyCode, {
    notification: _ctx.notification,
    onCopied: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('copied')))
  }, _createSlots({
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.embedCode), 1)
    ]),
    action: _withCtx(({ copied, copyCode }) => [
      _renderSlot(_ctx.$slots, "action", {
        copied: copied,
        copyCode: copyCode
      })
    ]),
    _: 2
  }, [
    (!_ctx.noTitle)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _cache[1] || (_cache[1] = _createTextVNode("Embed Code"))
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.noDescription)
      ? {
          name: "description",
          fn: _withCtx(() => [
            _cache[2] || (_cache[2] = _createTextVNode("Share this embed code with anyone that will insert it into your website, such as a web developer."))
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["notification"]))
}