import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 overflow-y-auto flex items-center justify-center" }
const _hoisted_2 = { class: "flex gap-2 flex-col flex-1 text-center items-center" }
const _hoisted_3 = { class: "flex text-2xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "flex flex-col text-base text-gray-700 leading-6 w-3/4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.notFound), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1),
      (!_ctx.query)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true)
    ])
  ]))
}