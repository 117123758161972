import { openBlock, createBlock, createVNode } from 'vue'

// Sometimes we are going to be missing some heroicons
// This is pulled from the heroicons/vue package and modified to work in this context

export default function render() {
  return (
    openBlock(),
    createBlock(
      'svg',
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 17 18'
      },
      [
        createVNode('path', {
          stroke: 'currentColor',
          'stroke-width': '2',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          d: 'M5.16634 4.83333V11.5C5.16634 12.4205 5.91253 13.1667 6.83301 13.1667H11.833M5.16634 4.83333V3.16667C5.16634 2.24619 5.91253 1.5 6.83301 1.5H10.6545C10.8755 1.5 11.0875 1.5878 11.2438 1.74408L14.9223 5.42259C15.0785 5.57887 15.1663 5.79083 15.1663 6.01184V11.5C15.1663 12.4205 14.4201 13.1667 13.4997 13.1667H11.833M5.16634 4.83333H3.83301C2.72844 4.83333 1.83301 5.72876 1.83301 6.83333V14.8333C1.83301 15.7538 2.5792 16.5 3.49967 16.5H9.83301C10.9376 16.5 11.833 15.6046 11.833 14.5V13.1667'
        })
      ]
    )
  )
}
