import { Ref } from 'vue'
import * as validators from '@vuelidate/validators'
import { ValidationRule } from '@vuelidate/core'

/**
 * This is a custom enum that will determine the validation messages for each of the
 * various vuelidate validators. This will allow us to implement i18n and other things
 * down the line if necessary, and also creates a block for usage.
 */
export enum ValidationMessages {
  Required = 'This field is required.',
  RequiredImage = 'An image is required.',
  Email = 'Please enter a valid email address.',
  Decimal = 'Please enter a valid decimal input',
  Numeric = 'Please enter a valid numeric input',
  MaxLength = 'This field is limited to {{ length }} characters.',
  MinValue = 'This field must be greater than or equal to {{ min }}.',
  MaxValue = 'This field must be less than or equal to {{ max }}.',
  URL = 'Please enter a valid url.',
  Between = 'This field must be between {{ min }} and {{ max }}.',
  Password = 'This field must be a minimum of 8 characters and contain at least 1 uppercase and lowercase letter, number and special character.',
  MatchingPasswords = 'The passwords entered do not match, please check and re-enter your passwords.'
}

/** @see https://vuelidate-next.netlify.app/validators.html#decimal */
export function decimal(customMessage?: string): ValidationRule {
  return validators.helpers.withMessage(customMessage ?? ValidationMessages.Numeric, validators.decimal)
}

/** @see https://vuelidate-next.netlify.app/validators.html#required */
export function required(): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.Required, validators.required)
}

/** @see https://vuelidate-next.netlify.app/custom_validators.html */
export function requiredCustomErrorMessage(message: string): ValidationRule {
  return validators.helpers.withMessage(message, validators.required)
}

/** @see https://vuelidate-next.netlify.app/validators.html#required */
export function requiredImage(): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.RequiredImage, validators.required)
}

/** @see https://vuelidate-next.netlify.app/validators.html#email */
export function email(): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.Email, validators.email)
}

/** @see https://vuelidate-next.netlify.app/validators.html#maxlength */
export function maxLength(length: number): ValidationRule {
  const message = ValidationMessages.MaxLength.replace('{{ length }}', length.toString())
  return validators.helpers.withMessage(message, validators.maxLength(length))
}

/** @see https://vuelidate-next.netlify.app/validators#numeric */
export function numeric(customMessage?: string): ValidationRule {
  const message = customMessage || ValidationMessages.Numeric
  return validators.helpers.withMessage(message, validators.numeric)
}

/** @see https://vuelidate-next.netlify.app/validators.html#minvalue */
export function minValue(min: number, customMessage?: string): ValidationRule {
  const message = customMessage || ValidationMessages.MinValue.replace('{{ min }}', min.toString())
  return validators.helpers.withMessage(message, validators.minValue(min))
}

/** @see https://vuelidate-next.netlify.app/validators#maxvalue */
export function maxValue(max: number, customMessage?: string): ValidationRule {
  const message = customMessage || ValidationMessages.MaxValue.replace('{{ max }}', max.toString())
  return validators.helpers.withMessage(message, validators.maxValue(max))
}

/** @see https://vuelidate-next.netlify.app/validators.html#url */
export function url(): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.URL, validators.url)
}

/** @see https://vuelidate-next.netlify.app/validators.html#between */
export function between(min: number, max: number, customMessage?: string): ValidationRule {
  const message = customMessage || ValidationMessages.Between.replace('{{ min }}', min.toString()).replace('{{ max }}', max.toString())
  return validators.helpers.withMessage(message, validators.between(min, max))
}

/** @see https://vuelidate-next.netlify.app/validators.html#requiredif */
export function requiredIf(callback: () => boolean): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.Required, validators.requiredIf(callback))
}

/** @see https://vuelidate-next.netlify.app/validators.html#sameas */
export function passwordsMatch(locator: Ref<any>): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.MatchingPasswords, (value: string) => sameAsValidator(value, locator))
}

/** @see https://vuelidate-next.netlify.app/validators.html#sameas */
export function password(): ValidationRule {
  return validators.helpers.withMessage(ValidationMessages.Password, passwordValidator)
}

/**
 * This is a fixed implementation for the same as validator from vuelidate. This
 * will allow us to match passwords, etc.
 * @param {string} value - the comparator value.
 * @param {any} ref - the element reference to check
 * @return {boolean}
 */
export function sameAsValidator(value: string, ref: Ref<any>): boolean {
  if (!value || !value.length || !ref || !ref.value) {
    return true
  }

  return value === ref.value.value
}

/**
 * This is a custom validator for vuelidate that will allow us to validate
 * specific password parameters.
 *
 * @param value {string} - the value to validate.
 * @return {boolean}
 */
function passwordValidator(value: string): boolean {
  // If there is no value provided, then we want to just go ahead and
  // return true as this field is always optional.
  if (!value || value.length < 1) {
    return true
  }

  return (
    // prettier-ignore
    // At least 8 characters long
    value.length >= 8
    // At least 1 uppercase
    && /[A-Z]/.test(value)
    // At least 1 lowercase character
    && /[a-z]/.test(value)
    // At least 1 special character
    && /\W|_/.test(value)
    // At least 1 number
    && /\d/.test(value)
  )
}

/**
 * This is a custom validator for vuelidate that will allow us to validate
 * specific passed url slug parameters.
 *
 * @param value {string} - the value to validate
 * @return {boolean}
 */
export function urlSlug(value: unknown): boolean {
  // If there is no value provided, then we want to just go ahead and
  // return true as this field is always optional.
  if (!value || (value as string).length < 1) {
    return true
  }

  return (
    // Only Alphanumeric and hyphens
    /[a-zA-Z0-9-]+/.test(value as string)
  )
}
