import RestService from '@/modules/api/rest-service'
import { Model, QueryRequest, QueryResponse } from '@/modules/api/types'
// stores & services
import CompanyStore from '@/modules/api/stores/company'

export default new (class CompanyProjectRepository extends RestService {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    if (!CompanyStore.$state.selected) {
      return ''
    }

    return `/companies/${CompanyStore.$state.selected.id}/projects`
  }

  /**
   * @override
   * @see RestService.stub
   */
  public stub(): Model {
    return {
      company_id: CompanyStore.$state.selected.id,
      file: null,
      file_id: null,
      title: null,
      description: null,
      latitude: null,
      longitude: null,
      url_slug: null,
      is_featured: false,
      is_paid: false,
      view_access_type: 'private',
      edit_access_type: 'private'
    }
  }

  /**
   * Return a list of all projects (non protected and filtered) so that we can
   * show all projects for selection in special cases.
   *
   * @param params {QueryRequest}
   * @return {Promise<Model>}
   */
  public async all(params?: QueryRequest): Promise<QueryResponse> {
    return (await this.$axios.get('/projects', { params })).data
  }

  /**
   * Clones a project record and all associated assets.
   *
   * @param project {Project} - the project to clone.
   * @param payload {Model} - a payload to send to the api
   * @return {Promise<void>}
   */
  public async clone(project: Model, payload: Model | null): Promise<void> {
    await this.$axios.post(`${this.endpoint}/${project.id}/clone`, payload)
  }

  /**
   * Assigns the passed project to the current selected company.
   *
   * @param project {Model} - the project to assign.
   * @return {Promise<void>}
   */
  public async assign(project: Model): Promise<void> {
    await this.$axios.post(`${this.endpoint}/${project.id}/assign`)
  }

  /**
   * Unassigns the passed project to the current selected company.
   *
   * @param project {Model} - the project to assign.
   * @return {Promise<void>}
   */
  public async unassign(project: Model): Promise<void> {
    await this.$axios.post(`${this.endpoint}/${project.id}/unassign`)
  }
})()
