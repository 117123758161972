import { App } from 'vue'

/**
 * This plugin will initialize the script tag for intercom so that it is available
 * in the application through the window api.
 *
 * @see https://developers.intercom.com/installing-intercom/docs/basic-javascript
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useIntercom(app: App<Element>): Promise<App> {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://widget.intercom.io/widget/c1xi3fnv'
    const firstScript = document.getElementsByTagName('script')[0] as any
    firstScript.parentNode.insertBefore(script, firstScript)
    script.onload = () => {
      resolve(app)
    }
  })
}
