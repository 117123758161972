import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.routerLinkTo ? 'router-link' : 'div'), {
    class: "table--row block",
    to: _ctx.routerLinkTo,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onRowClick($event)), ["stop"]))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to"]))
}