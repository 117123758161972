import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewbox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M17.6567 7.34315C14.5325 4.21895 9.46723 4.21895 6.34303 7.34315C3.67857 10.0076 3.28558 14.0862 5.16813 17.1666C5.45613 17.6378 5.30758 18.2533 4.83633 18.5413C4.36508 18.8293 3.74959 18.6808 3.46159 18.2095C1.10843 14.3591 1.59615 9.2616 4.92882 5.92893C8.83406 2.02369 15.1657 2.02369 19.071 5.92893C22.4036 9.2616 22.8913 14.3591 20.5382 18.2095C20.2502 18.6808 19.6347 18.8293 19.1634 18.5413C18.6922 18.2533 18.5436 17.6378 18.8316 17.1666C20.7142 14.0862 20.3212 10.0076 17.6567 7.34315ZM16.15 10.3942C16.635 10.6585 16.8139 11.2659 16.5496 11.7508L14.2638 15.9448C14.7235 16.472 15.002 17.1613 15.002 17.9157C15.002 19.5725 13.6588 20.9157 12.002 20.9157C10.3451 20.9157 9.00198 19.5725 9.00198 17.9157C9.00198 16.2588 10.3451 14.9157 12.002 14.9157C12.1795 14.9157 12.3534 14.9311 12.5225 14.9607L14.7934 10.7937C15.0577 10.3088 15.6651 10.1299 16.15 10.3942ZM12.002 16.9157C11.4497 16.9157 11.002 17.3634 11.002 17.9157C11.002 18.4679 11.4497 18.9157 12.002 18.9157C12.5543 18.9157 13.002 18.4679 13.002 17.9157C13.002 17.3634 12.5543 16.9157 12.002 16.9157Z",
      fill: "#222222"
    }, null, -1)
  ])))
}