import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-labels flex-1" }
const _hoisted_2 = ["type"]
const _hoisted_3 = {
  key: 1,
  type: "optional"
}
const _hoisted_4 = { class: "flex flex-col gap-1 w-80" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { type: "validation-error" }
const _hoisted_7 = {
  key: 1,
  type: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row space-between form-field items-center", [_ctx.width ? `w-${_ctx.width}` : 'flex-1', _ctx.validator?.$errors.length ? 'has-validation-error' : '', _ctx.asInternal ? 'internal' : '', _ctx.asPlain ? 'plain' : '', !_ctx.label ? 'mt-5' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            type: _ctx.subheader ? 'subheader' : '',
            style: _normalizeStyle([_ctx.boldLabel ? 'font-weight: 500' : '', 'font-size: 1rem'])
          }, _toDisplayString(_ctx.label), 13, _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.required)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, "Optional"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["form-input", { disabled: _ctx.disabled }])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", {
            disabled: _ctx.disabled,
            hasValidationError: _ctx.validator?.$errors.length
          })
        ]),
        (!_ctx.asPlain)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["absolute", ['p-0.5', _ctx.iconClass]])
            }, [
              (_ctx.validator?.$errors.length)
                ? (_openBlock(), _createBlock(_component_ExclamationCircleIcon, {
                    key: 0,
                    class: "m-0 text-red-800 w-5 h-5"
                  }))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.validator && _ctx.showErrors)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.validator.$errors, (error) => {
            return (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(error.$message), 1))
          }), 256))
        : _createCommentVNode("", true),
      (_ctx.info)
        ? (_openBlock(), _createElementBlock("label", _hoisted_7, _toDisplayString(_ctx.info), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}