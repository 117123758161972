/* eslint-disable */
import { DateTime } from 'luxon'
import Compressor from 'compressorjs'
import { Model } from '@/modules/api/types'
import { MeetingSettingOption } from './composables/use-book-a-meeting'
import { watch, WatchSource } from 'vue'
import { SlideType } from '@/modules/api/types/deck'

/**
 * Compresses a file using the compressor js algorithm. This is not necessarily a good
 * idea, but we need to do this to lower upload speeds.
 *
 * @param file {File} - the html file element
 * @return {Promise<File>}
 */
export async function compress(file: File): Promise<File> {
  // tslint-disable
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      // The compressor uses its own arbitrary scale instead of actually
      // rationing the original image. Experimentally this seems to be about the right
      // value to actually reduce the image size.
      quality: 0.8,
      checkOrientation: false,
      success(result: File) {
        resolve(result)
      },
      error(err: Error) {
        reject(err)
      }
    })
  })
}

/**
 * Rounds the given number to the given amount of decimals
 *
 * @param value {number}
 * @param decimals {number}
 */
export function round(value: number, decimals: number): number {
  return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`)
}

/**
 * Computes the index adjustment required. If there are slides that will be moved past the new
 * index, we need to add them to the index where this slide is being set. For example.  If we are
 * moving slide 8 and 9 to slide 10 and 11, the index for original slide 8 assumes that slide 9
 * has also moved, but this will happen in a second step - so we need to add 1 to the index slide 8 is
 * being moved to.
 *
 * @param newIndicies - the requested indicies after the movement.
 * @param oldIndicies - the indicies prior to the movement
 * @param index - the index that we want to move to item to
 * @param i - the index of the item for which the translation is being calculated.
 */
export function translateIndicies(newIndicies: any[], oldIndicies: any[], index: number, i: number): number {
  let translation = 0
  // Iterate through the items (starting with i + 1) that still need to be moved to
  // determine what adjustment need to be made to this item to ensure it takes into
  // account the future movements.
  for (let j = i + 1; j < newIndicies.length; j++) {
    const newIndex = newIndicies[j].index
    const oldIndex = oldIndicies[j].index
    // Is this a move down
    const movingDown = index <= oldIndicies[i].index

    // Adjust by 1 if:
    // The new index of the item that has yet to be moved is > the final index of this item
    const cond1 = newIndex > index

    let cond2 = true
    // If moving down, also check that the old index of the item that has yet to be moved
    // is <= the final index of this item
    if (movingDown) {
      cond2 = oldIndex <= index
    }

    if (cond1 && cond2) {
      translation = translation + 1
    }
  }

  return translation
}

/**
 * Formats a trade value to 3 decimals if the last decimal is non-zero and to 2 decimals otherwise
 */
export function formatTradeValue(price: number): string {
  const threeDecimal = price.toFixed(3)

  if (threeDecimal.charAt(threeDecimal.length - 1) === '0') {
    return threeDecimal.substring(0, threeDecimal.length - 1)
  }

  return threeDecimal
}

/**
 * Format the activity timestamp according to the designs. This seems like super overkill
 * to be trying to do so many conversions; but this matches iOS timestamps.
 *
 * @param activity {Model} - the model to format the timestamps for.
 * @return {string}
 */
export function formatTimestamp(activity: Model): string {
  const startDate = activity.start_date
  const startTime = activity.start_time
  const endDate = activity.end_date
  const endTime = activity.end_time

  if (startTime && endTime) {
    const start = DateTime.fromISO(startTime)
    const end = DateTime.fromISO(endTime)
    if (start.toISODate() !== end.toISODate()) {
      return `${start.toFormat('LLL dd, yyyy HH:mm')} - ${end.toFormat('LLL dd, yyyy HH:mm')}`
    }

    return `${start.toFormat('LLL dd, yyyy • HH:mm')} - ${end.toFormat('HH:mm')}`
  }

  const withYear = 'LLL dd, yyyy'
  if (startDate && endDate) {
    const start = DateTime.fromISO(startDate)
    const end = DateTime.fromISO(endDate)
    if (start.year !== end.year) {
      return `${start.toFormat(withYear)} - ${end.toFormat(withYear)}`
    }

    return `${start.toFormat('LLL dd')} - ${end.toFormat(withYear)}`
  }

  return DateTime.fromISO(startDate).toFormat(withYear)
}

/**
 * Select the first active request setting object.
 * From the API, the first one is the current logged-in user by default.
 */
export function setDefaultMeetingBookingUser(cb: (setting: number) => void, meetingsSettingsUsers: MeetingSettingOption[]): void {
  // API response always puts logged-in user at top when present
  const firstAvailableSetting = meetingsSettingsUsers.find(u => !u.disabled)
  if (firstAvailableSetting) {
    cb(firstAvailableSetting.value)
  }
}

export type BookMeetingStatus = null | 'loading' | 'none-available' | 'not-setup' | 'ready'

/**
 * Pre-compute some boolean checks related to Book-a-Meeting slide type
 */
export function getBookMeetingStatus(
  meetingsSettingsUsers: MeetingSettingOption[],
  meetingsSettingCurrentUser: Model | null,
  working: number
): BookMeetingStatus {
  if (working) {
    return 'loading'
  }

  if (!meetingsSettingsUsers.every(user => user.disabled)) {
    return 'ready'
  }

  if (!meetingsSettingCurrentUser) {
    return 'not-setup'
  }

  // all are disabled, current user can enable their own
  return 'none-available'
}

/**
 * Wait for a WatchSource to equal a certain value.
 */
export async function waitFor<T>(ref: WatchSource<T>, toEqual: T, timeout = 8_000): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject()
      unwatch()
    }, timeout)
    const unwatch = watch(ref, val => {
      if (val === toEqual) {
        resolve()
        unwatch()
        clearTimeout(timer)
      }
    })
  })
}

/**
 * Converts slide type shorthand form into a more human readable form of the type
 * @param type - slide type in its shorthand form
 * @returns full name of the slide type
 */

export function getFullSlideTypeName(type: SlideType): string | null {
  switch (type) {
    case 'P':
      return 'PDF'
    case 'M':
      return '3D'
    case 'S':
      return 'Site'
    case 'I':
      return 'Image'
    case 'V':
      return 'Video'
    case 'D':
      return 'Dynamic'
    case 'H':
      return 'Header'
    case 'E':
      return 'Meeting'
    default:
      return null
  }
}

/**
 * Get a status text from a status code if possible
 * @param statusCode number - the status code of the response
 * @returns string - the status text of the response or "Unknown Error" if the status is not mapped
 */

export function getStatusTextFromStatusCode(statusCode: number): string {
  const lookup: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Access Denied',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
  }

  return statusCode in lookup ? lookup[statusCode] : 'Unknown Error'
}
