import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex items-center justify-end space-x-4 border-t border-gray-200 p-6" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = {
  key: 0,
  class: "text-xs text-gray-500 leading-6"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "capitalize" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    static: "",
    onEsc: _ctx.cancel,
    width: "max-w-3xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, { onClick: _ctx.cancel }, null, 8, ["onClick"]),
        _createVNode(_component_DialogTitle, {
          class: "text-xl font-medium leading-6 line-clamp-2 text-gray-900 capitalize",
          as: "h3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.state.record.name), 1)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_2, "Last Updated on " + _toDisplayString(_ctx.$filters.date(_ctx.state.record.updated_at)), 1)
      ]),
      (_ctx.state.record.url)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-full h-full",
            src: _ctx.state.record.url
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-base text-gray-900 font-semibold leading-8" }, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-xs text-gray-500" }, null, -1)),
          (_ctx.state.associations)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "Present in", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.associationKeys, (key, index) => {
                  return (_openBlock(), _createElementBlock("span", { key: index }, [
                    (_ctx.state.associations[key].length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, " " + _toDisplayString(_ctx.state.associations[key].length) + " " + _toDisplayString(key.replace('_', ' ')), 1),
                          (index < _ctx.state.associationKeys.length - 3)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, ","))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.props.allowDelete)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              theme: "danger",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.destroy && _ctx.destroy(...args))),
              disabled: _ctx.state.working
            }, "Delete", 8, _hoisted_10))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}