import Store from '@/modules/api/store'
import { State } from '@/modules/api/types'
// stores
import CompanyStore from '@/modules/api/stores/company'
import SessionStore from '@/modules/api/stores/session'
import DeckStore from '@/modules/api/stores/deck'

export default new (class ApplicationStore extends Store<State> {
  /** @constructor */
  constructor() {
    super({
      /**
       * Determines whether or not a loading mask should be displayed. When true
       * it will overlay the page so that nothing can be interacted with.
       *
       * @type {Boolean}
       */
      loading: false
    })
  }

  /**
   * Set the loading state which will trigger the load mask to show/hide.
   *
   * @param loading {boolean}
   */
  public set loading(loading: boolean) {
    this.state.loading = loading
  }

  //
  // methods
  //

  /**
   * Resets all store data, and purges the local storage so that no future information
   * is stored in any location.
   *
   * @param clearBrowserStorage {boolean} - whether or not to purge cookies in localStorage.
   * @return {void}
   */
  public resetStores(clearBrowserStorage = true): void {
    // Clear local storage of all keys in the cache
    if (clearBrowserStorage) {
      window.localStorage.clear()
    }

    // Clear all stores to make sure that no data is persistent in any fashion. This will
    // prevent stale data from being stored when there is a logout->login action.
    this.reset()
    DeckStore.reset()
    CompanyStore.reset()
    SessionStore.reset()
  }
})()
