import RestService from '@/modules/api/rest-service'
// lib
import { compress } from '@/modules/common/utilities'
import EventBus from '@/modules/common/event-bus'
// stores & services
import CompanyStore from '@/modules/api/stores/company'
// types & enums
import { Model, QueryResponse } from '@/modules/api/types'

export default new (class FileRepository extends RestService {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    if (!CompanyStore.$state.selected) {
      return ''
    }

    return `/companies/${CompanyStore.$state.selected.id}/shelf/files`
  }

  /**
   * Returns a promise that when resolved will create a new record.
   *
   * @override
   * @see RestService.create
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the created model record from the api
   */
  public async create(model: Model): Promise<Model> {
    EventBus.emit('file-upload:start')

    const formData = new FormData()
    const file = model.file.type.includes('image') ? await compress(model.file) : model.file

    formData.append('file', file, model.file.name)
    formData.append('name', model.name || model.file.name)
    formData.append('company_id', model.company_id.toString())
    formData.append('folder_id', model.folder_id.toString())
    // conditional field -- if you try to send null it will cast it to a string
    // and your gonna have a bad time.
    if (model.description) {
      formData.append('description', model.description)
    }

    // Isolate the response and trigger an event to denote that the file is finished
    // uploading. Since this is an async/await function it will wait to denote until
    // the upload is completed.
    const response = (
      await this.$axios.post(`${this.endpoint}`, formData, {
        transformRequest: formData => formData
      })
    ).data
    EventBus.emit('file-upload:finished')

    return response
  }

  /**
   * Restores a specific file back to its original location. This is essentially an "un-delete"
   * and will retain all original data.
   *
   * @param file {Model} - the file model to restore.
   * @return {Promise<void>}
   */
  public async restore(file: Model): Promise<void> {
    return (await this.$axios.put(`/companies/${CompanyStore.$state.selected.id}/shelf/trash/files/${file.id}/restore`)).data
  }

  /**
   * Returns a list of files that are currently in the trash bin.
   *
   * @param params {QueryRequest} - the params to filter there result set by.
   * @return {Promise<QueryResponse>} - the direct response from the api.
   */
  public async trash(params?: any): Promise<QueryResponse> {
    return (await this.$axios.get(`/companies/${CompanyStore.$state.selected.id}/shelf/trash/files`, { params })).data
  }

  /**
   * Returns a list of associations with a file so that we can safely delete from the shelf.
   *
   * @param file {Model} - the id for the file to check.
   * @return {Promise<QueryResponse>} - the direct response from the api.
   */
  public async associatedEntities(file: Model): Promise<QueryResponse> {
    return (await this.$axios.get(`/companies/${CompanyStore.$state.selected.id}/shelf/files/${file.id}/associated-entities`)).data
  }

  /**
   * Returns a list of images associated with a pdf file so that we can preview the images.
   *
   * @param file {Model} - the id for the file to check.
   * @return {Promise<Model[]>} - the direct response from the api.
   */
  public async images(file: Model): Promise<Model[]> {
    return (await this.$axios.get(`/companies/${CompanyStore.$state.selected.id}/shelf/files/${file.id}/images`)).data
  }
})()
