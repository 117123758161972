import { App } from 'vue'
// stores & services
import SessionStore from '@/modules/api/stores/session'

/**
 * This exported method will apply the role method through a global directive. This will
 * allow us to prevent access to various items without bloating controller code, and make
 * it all nice and dry.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useRoleDirective(app: App): App {
  app.directive('role', {
    /**
     * If the user does not have the role supplied, then we remove the element
     * so that it cannot be accessed.
     *
     * @note
     * Do *NOT* just hide the element -- you can hack the browser and just un-hide it
     * regardless of permission or role. Make sure to remove the element.
     *
     * @param el {HTMLElement}
     * @param binding {Set}
     */
    mounted(el, binding) {
      if (!SessionStore.hasRole(binding.value)) {
        el.remove()
      }
    }
  })

  return app
}
