import { Axios } from '@/modules/api/axios'
import { Model, QueryResponse } from '@/modules/api/types'
import { AccessResource, AccessType } from '@/modules/api/enums'

export default new (class AccessService extends Axios {
  /**
   * This method will pull all access currently granted for a specific access type, and
   * specified resource. This will allow us to manage various resource related access.
   *
   * @param resource {Model} - the resource to pull data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   * @param accessType {AccessType} - the type of access to pull data for.
   * @return {Promise<QueryResponse>}
   */
  public async query(resource: Model, resourceType: AccessResource, accessType: AccessType): Promise<QueryResponse> {
    return (await this.$axios.get(`/access/${accessType}/${resourceType}/${resource.id}/users/`, { params: { limit: 1000 } })).data
  }

  /**
   * Grant access to the passed resource, resourceType, accessType, and user. This will grant
   * privileges to the passed user based on what is being specified.
   *
   * @param user {Model} - the user to grant access to.
   * @param resource {Model} - the resource to pull data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   * @param accessType {AccessType} - the type of access to pull data for.
   * @return {Model}
   */
  public async grant(user: Model, resource: Model, resourceType: AccessResource, accessType: AccessType): Promise<Model> {
    return this.$axios.post(`/access/${accessType}/${resourceType}/${resource.id}/${user.id}`)
  }

  /**
   * Revoke access to the passed resource, resourceType, accessType, and user. This will revoke
   * privileges to the passed user based on what is being specified.
   *
   * @param user {Model} - the user to grant access to.
   * @param resource {Model} - the resource to pull data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   * @param accessType {AccessType} - the type of access to pull data for.
   * @return {Model}
   */
  public async revoke(user: Model, resource: Model, resourceType: AccessResource, accessType: AccessType): Promise<Model> {
    return this.$axios.delete(`/access/${accessType}/${resourceType}/${resource.id}/${user.id}`)
  }
})()
