import Store from '@/modules/api/store'
import SessionStore from '@/modules/api/stores/session'
// types & enums
import { State, Model } from '@/modules/api/types'
import { Role, Permission, SubscriptionTier } from '@/modules/api/enums'

export default new (class CompanyStore extends Store<State> {
  /** @constructor */
  constructor() {
    super({
      // The current selected company in the application. This will filter a lot of
      // different methods and endpoints by the company id coming from it.
      selected: null
    })
  }

  /**
   * Set the selected company, and inherit any permissions thats needed.
   * @param company
   */
  public async select(company: Model) {
    // NOTE: do not use $state here -- this is an internal setter.
    this.state.selected = company

    // This looks like a redundant call, but this makes sure that everything is
    // 100% in sync, and up to date with the rest of the application and backend.
    window.localStorage.setItem('v2.company', JSON.stringify(company))

    // Refresh the selected permissions so that we do not have to reload the
    // page, and can manage all the permissions internally.
    if (SessionStore.hasRole(Role.InternalAdmin) || company.payment_plan?.plan_tier !== SubscriptionTier.Silver) {
      SessionStore.addPermission(Permission.DeckAccess)
    }
  }

  /**
   * Based on the internal store state, we need to return a boolean value as
   * to whether or not the company has the supplied feature.
   *
   * @param feature {string} - the feature to check against.
   */
  public hasFeatureEnabled(feature: string): boolean {
    return this.state.selected.features_enabled.some((flag: string) => flag.toUpperCase() === feature.toUpperCase())
  }

  /**
   * Either select previously selected company or see if the user only has one company or push to
   * company page to select one.
   */
  public async createCompanyState(): Promise<void> {
    const company = window.localStorage.getItem('v2.company') || null
    if (company) {
      await this.select(JSON.parse(company))
    }
  }
})()
