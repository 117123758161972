import { App } from 'vue'
import {
  ArchiveIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CodeIcon,
  CogIcon,
  ColorSwatchIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  DocumentTextIcon,
  DownloadIcon,
  DuplicateIcon,
  ExclamationIcon as ExclamationIconOutline,
  ExternalLinkIcon,
  EyeIcon,
  EyeOffIcon,
  FilmIcon,
  FolderIcon,
  GlobeAltIcon,
  HomeIcon,
  InboxIcon,
  InformationCircleIcon,
  LinkIcon,
  LockClosedIcon,
  LogoutIcon,
  MailIcon,
  NewspaperIcon,
  PencilIcon as PencilIconOutline,
  PhotographIcon,
  PlusIcon,
  RefreshIcon,
  SearchIcon,
  ShareIcon,
  SupportIcon,
  SwitchVerticalIcon,
  TemplateIcon,
  TrashIcon,
  UploadIcon,
  UserIcon,
  VideoCameraIcon,
  ViewGridIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '@heroicons/vue/outline'
import {
  AnnotationIcon,
  CalendarIcon,
  ChatIcon,
  CheckIcon,
  CurrencyDollarIcon,
  ChipIcon,
  DatabaseIcon,
  DotsHorizontalIcon,
  DotsVerticalIcon,
  DuplicateIcon as DuplicateIconSolid,
  ExclamationCircleIcon,
  ExclamationIcon,
  LocationMarkerIcon,
  LockOpenIcon,
  PencilIcon,
  ReplyIcon,
  SelectorIcon,
  XCircleIcon,
  XIcon
} from '@heroicons/vue/solid'

// Componentized icons to make up for missing ones
import Square2Stack from '@/assets/icons/componentized/Square2Stack'

/**
 * This plugin will globalize the icons used throughout the application so that
 * we can not have to bloat our controller code with icon imports.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useHeroIcons(app: App): App {
  app.component('AnnotationIcon', AnnotationIcon)
  app.component('ArchiveIcon', ArchiveIcon)
  app.component('ArrowDownIcon', ArrowDownIcon)
  app.component('ArrowUpIcon', ArrowUpIcon)
  app.component('CalendarIcon', CalendarIcon)
  app.component('CashIcon', CashIcon)
  app.component('ChatIcon', ChatIcon)
  app.component('CheckCircleIcon', CheckCircleIcon)
  app.component('CheckIcon', CheckIcon)
  app.component('ChevronDownIcon', ChevronDownIcon)
  app.component('ChevronLeftIcon', ChevronLeftIcon)
  app.component('ChevronRightIcon', ChevronRightIcon)
  app.component('ChevronUpIcon', ChevronUpIcon)
  app.component('CodeIcon', CodeIcon)
  app.component('CogIcon', CogIcon)
  app.component('ColorSwatchIcon', ColorSwatchIcon)
  app.component('CreditCardIcon', CreditCardIcon)
  app.component('CurrencyDollarIcon', CurrencyDollarIcon)
  app.component('ChipIcon', ChipIcon)
  app.component('DatabaseIcon', DatabaseIcon)
  app.component('DocumentDuplicateIcon', DocumentDuplicateIcon)
  app.component('DocumentIcon', DocumentIcon)
  app.component('DocumentTextIcon', DocumentTextIcon)
  app.component('DotsHorizontalIcon', DotsHorizontalIcon)
  app.component('DotsVerticalIcon', DotsVerticalIcon)
  app.component('DownloadIcon', DownloadIcon)
  app.component('DuplicateIcon', DuplicateIcon)
  app.component('DuplicateIconSolid', DuplicateIconSolid)
  app.component('ExclamationCircleIcon', ExclamationCircleIcon)
  app.component('ExclamationIcon', ExclamationIcon)
  app.component('ExclamationIconOutline', ExclamationIconOutline)
  app.component('ExternalLinkIcon', ExternalLinkIcon)
  app.component('EyeIcon', EyeIcon)
  app.component('EyeOffIcon', EyeOffIcon)
  app.component('FilmIcon', FilmIcon)
  app.component('FolderIcon', FolderIcon)
  app.component('GlobeAltIcon', GlobeAltIcon)
  app.component('HomeIcon', HomeIcon)
  app.component('InboxIcon', InboxIcon)
  app.component('InformationCircleIcon', InformationCircleIcon)
  app.component('LinkIcon', LinkIcon)
  app.component('LocationMarkerIcon', LocationMarkerIcon)
  app.component('LockClosedIcon', LockClosedIcon)
  app.component('LockOpenIcon', LockOpenIcon)
  app.component('LogoutIcon', LogoutIcon)
  app.component('MailIcon', MailIcon)
  app.component('NewspaperIcon', NewspaperIcon)
  app.component('PencilIcon', PencilIcon)
  app.component('PencilIconOutline', PencilIconOutline)
  app.component('PhotographIcon', PhotographIcon)
  app.component('PlusIcon', PlusIcon)
  app.component('RefreshIcon', RefreshIcon)
  app.component('ReplyIcon', ReplyIcon)
  app.component('SearchIcon', SearchIcon)
  app.component('SelectorIcon', SelectorIcon)
  app.component('ShareIcon', ShareIcon)
  app.component('Square2Stack', Square2Stack)
  app.component('SupportIcon', SupportIcon)
  app.component('SwitchVerticalIcon', SwitchVerticalIcon)
  app.component('TemplateIcon', TemplateIcon)
  app.component('TrashIcon', TrashIcon)
  app.component('UploadIcon', UploadIcon)
  app.component('UserIcon', UserIcon)
  app.component('VideoCameraIcon', VideoCameraIcon)
  app.component('ViewGridIcon', ViewGridIcon)
  app.component('XCircleIcon', XCircleIcon)
  app.component('XIcon', XIcon)
  app.component('ZoomInIcon', ZoomInIcon)
  app.component('ZoomOutIcon', ZoomOutIcon)
  return app
}
