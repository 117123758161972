<template>
  <div id="footer" class="w-full bg-black h-60 flex flex-col justify-evenly">
    <div class="flex justify-center">
      <picture>
        <source srcset="@/assets/branding/vrify-circle-logo.webp" />
        <img alt="VRIFY" src="@/assets/branding/vrify-circle-logo.png" />
      </picture>
    </div>
    <div class="flex justify-end text-white pr-6">
      <p>© {{ new Date().getFullYear() }} VRIFY Technology Inc. All rights reserved.</p>
    </div>
  </div>
</template>
