import { computed } from 'vue'
import useVuelidate from '@vuelidate/core'
// types & enums
import { Model } from '@/modules/api/types'

// This is a stopgap solution to vuelidate 2.0's not return validators
// type. It will allow us to bypass the errors. The passed value does
// fit the interface below, its just very "loosely" enforced.
interface Validators {
  [key: string]: any
}

/**
 * This is a composable mixin function that will allow form components to make use
 * of validation through vuelidate in a very DRY manner.
 *
 * @param rawValidators {any} - the validators object to provide.
 * @param model {Model} - the model data entity to validate against.
 * @return {any} - a mix of objects and functions.
 */
export default (rawValidators: Validators, model: Model): any => {
  // Build the vuelidate validators.
  const validators = computed(() => rawValidators)
  const validator = useVuelidate(validators, model)

  /**
   * This is a closure function which will use hoisting in order to access the form
   * validator completed, and return the method. This is so that we can make sure
   * that we have DRY functions in the form components.
   *
   * @return {boolean}
   */
  function valid(): boolean {
    validator.value.$reset()
    validator.value.$touch()
    return !validator.value.$invalid
  }

  /**
   * Reset the form validator, and hide all error messages from display. This will
   * be exposed so that parent components can inference and internally call this method.
   *
   * @return {void}
   */
  function reset(): void {
    validator.value.$reset()
  }

  return {
    validator,
    valid,
    reset
  }
}
