import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewbox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "12.2235",
      cy: "12",
      r: "6.06439",
      stroke: "currentColor",
      "stroke-width": "0.737108"
    }, null, -1),
    _createElementVNode("ellipse", {
      rx: "5.89687",
      ry: "1.47422",
      transform: "matrix(1 0 0 -1 12.2235 11.9998)",
      stroke: "currentColor",
      "stroke-width": "0.737108"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "12.2232",
      cy: "11.9998",
      rx: "5.89687",
      ry: "1.47422",
      stroke: "currentColor",
      "stroke-width": "0.737108",
      transform: "rotate(-90 12.2232 11.9998)"
    }, null, -1)
  ])))
}