import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/branding/v.png'


const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "px-6 pt-6"
}
const _hoisted_6 = {
  class: "text-sm text-gray-900 p-6 overflow-y-auto",
  style: {"max-height":"480px"}
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "flex flex-col space-y-6" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "w-9 h-9 bg-gray-900 rounded-full flex content-center justify-center items-center ml-auto"
}
const _hoisted_12 = { class: "text-white font-medium text-center" }
const _hoisted_13 = { class: "flex-1" }
const _hoisted_14 = { class: "text-base text-gray-900" }
const _hoisted_15 = { class: "text-sm text-gray-700" }
const _hoisted_16 = { class: "flex-1 flex items-center justify-end" }
const _hoisted_17 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_18 = ["id"]
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 1,
  class: "w-9 h-9 bg-gray-900 rounded-full flex content-center justify-center items-center ml-auto"
}
const _hoisted_21 = { class: "text-white font-medium text-center" }
const _hoisted_22 = { class: "flex-1" }
const _hoisted_23 = {
  key: 0,
  class: "text-base text-gray-900"
}
const _hoisted_24 = { class: "text-sm text-gray-700" }
const _hoisted_25 = { class: "flex-1 flex items-center justify-end" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "space-y-6" }
const _hoisted_28 = { class: "flex items-center space-x-4" }
const _hoisted_29 = { class: "flex-1 flex items-center justify-end" }
const _hoisted_30 = { class: "flex items-center justify-end space-x-4 p-6 border-t border-gray-100" }
const _hoisted_31 = { class: "flex-1 flex space-x-4 justify-start flex-1 text-left" }
const _hoisted_32 = { class: "flex-1 flex space-x-4 justify-end flex-1 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: "max-w-3xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, { onClick: _ctx.cancel }, null, 8, ["onClick"]),
        _createVNode(_component_DialogTitle, {
          class: "line-clamp-2 text-xl font-medium leading-6 text-black capitalize text-gray-900",
          as: "h3"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Secure Access")
          ])),
          _: 1
        }),
        _createElementVNode("p", _hoisted_2, [
          (_ctx.resourceType === _ctx.AccessResource.Deck)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Define who has access to edit and view this Deck. To give View Only access to a new user, first add the user by entering their email below, and then grant access once the user has been added."))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "Define who has access to edit and view this " + _toDisplayString(_ctx.resourceType) + ".", 1))
        ])
      ]),
      (_ctx.state.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Alert, {
              class: "mb-4",
              theme: "danger"
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.error), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.resourceType === _ctx.AccessResource.Deck)
          ? (_openBlock(), _createBlock(_component_Form, {
              key: 0,
              onSubmit: _withModifiers(_ctx.addNonAdmin, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormField, {
                      class: "-mt-4 mb-4",
                      validator: _ctx.validator.nonAdminEmail,
                      required: "",
                      iconClass: "top-2.5 right-24"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          class: "h-8 w-full rounded-md",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nonAdminEmail) = $event)),
                          name: "non-admin-email",
                          type: "email",
                          placeholder: "Enter an email to add a new user"
                        }, null, 512), [
                          [_vModelText, _ctx.state.nonAdminEmail]
                        ]),
                        _createElementVNode("button", {
                          class: "absolute right-2",
                          type: "submit",
                          theme: "copper",
                          size: "small",
                          disabled: !_ctx.state.nonAdminEmail
                        }, "Add User", 8, _hoisted_7)
                      ]),
                      _: 1
                    }, 8, ["validator"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-gray-700 rounded-md bg-gray-50 py-1 px-6" }, "Admins", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.admins, (user) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex items-center space-x-6 pb-6",
              id: `user-${user.id}`,
              key: user.id
            }, [
              (user.profile_image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "rounded-full h-9 w-9 border border-gray-200 ml-auto",
                    src: user.profile_image
                  }, null, 8, _hoisted_10))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(user.initials), 1)
                  ])),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(user.name), 1),
                _createElementVNode("p", _hoisted_15, _toDisplayString(user.email_address), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_Dropdown, {
                  class: "border-2 rounded-md py-2 px-4",
                  onChange: ($event: any) => (_ctx.toggle(user)),
                  modelValue: user.has_edit_access,
                  "onUpdate:modelValue": ($event: any) => ((user.has_edit_access) = $event),
                  "value-field": "value",
                  "label-field": "label",
                  options: [ { label: 'No Access', value: false }, { label: 'View & Edit', value: true }, ]
                }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
              ])
            ], 8, _hoisted_9))
          }), 128)),
          (_ctx.resourceType === _ctx.AccessResource.Deck && _ctx.state.nonAdmins.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-gray-700 rounded-md bg-gray-50 py-1 px-6" }, "Non-Admins", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.nonAdmins, (user) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex items-center space-x-6 pb-6",
                    id: `user-${user.id}`,
                    key: user.id
                  }, [
                    (user.profile_image)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "rounded-full h-9 w-9 border border-gray-200 ml-auto",
                          src: user.profile_image
                        }, null, 8, _hoisted_19))
                      : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, _toDisplayString(user.initials), 1)
                        ])),
                    _createElementVNode("div", _hoisted_22, [
                      (user.has_view_access)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_23, "Invite sent to"))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_24, _toDisplayString(user.email_address), 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      (user.has_view_access)
                        ? (_openBlock(), _createBlock(_component_Dropdown, {
                            key: 0,
                            class: "border-2 rounded-md py-2 px-4",
                            onChange: ($event: any) => (_ctx.revokeViewAccess(user)),
                            modelValue: user.has_view_access,
                            "onUpdate:modelValue": ($event: any) => ((user.has_view_access) = $event),
                            "value-field": "value",
                            "label-field": "label",
                            options: [ { label: 'No Access', value: false }, { label: 'View Only', value: true }, ]
                          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"]))
                        : (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "border-2",
                            theme: "secondary",
                            onClick: ($event: any) => (_ctx.grantViewAccess(user))
                          }, "Give View Only Access", 8, _hoisted_26))
                    ])
                  ], 8, _hoisted_18))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_27, [
            _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-gray-700 rounded-md bg-gray-50 py-1 px-6" }, "VRIFY Team Access", -1)),
            _createElementVNode("div", _hoisted_28, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-9 h-9 bg-gray-900 rounded-full flex content-center justify-center items-center ml-auto" }, [
                _createElementVNode("img", {
                  class: "w-4 h-4",
                  src: _imports_0
                })
              ], -1)),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-1" }, [
                _createElementVNode("p", { class: "text-base text-gray-900" }, "VRIFY Team Access"),
                _createElementVNode("p", { class: "text-sm text-gray-700" }, "Allow access to view and edit")
              ], -1)),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_Switch, {
                  class: _normalizeClass(["relative inline-flex flex-shrink-0 h-7 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75", _ctx.state.resource.allow_internal_access ? 'bg-gray-900' : 'bg-gray-200']),
                  modelValue: _ctx.state.resource.allow_internal_access,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.resource.allow_internal_access) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["flex items-center justify-center pointer-events-none inline-block h-6 w-6 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200", _ctx.state.resource.allow_internal_access ? 'translate-x-7' : 'translate-x-0']),
                      "aria-hidden": "true"
                    }, [
                      (_ctx.state.resource.allow_internal_access)
                        ? (_openBlock(), _createBlock(_component_CheckIcon, {
                            key: 0,
                            class: "w-4 h-4"
                          }))
                        : (_openBlock(), _createBlock(_component_XIcon, {
                            key: 1,
                            class: "w-4 h-4"
                          }))
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["modelValue", "class"])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          (_ctx.resourceType === _ctx.AccessResource.Deck)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                theme: "secondary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSecureLinkDialog(_ctx.state.resource)))
              }, "Share Secure Link"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("button", {
            theme: "copper",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, "Done")
        ])
      ]),
      _createVNode(_component_ConfirmationDialog, {
        ref: "RemoveAccessConfirmationRef",
        onConfirm: _ctx.revokeSelfAccess
      }, {
        title: _withCtx(({ record }) => [
          _createTextVNode("Revoke Access for " + _toDisplayString(record.name) + "?", 1)
        ]),
        message: _withCtx(({ record }) => [
          _createTextVNode("Are you sure you want to remove your access to this " + _toDisplayString(_ctx.resourceType) + "? If you remove your access, you will no longer be able to view or manage access to this " + _toDisplayString(_ctx.resourceType) + ".", 1)
        ]),
        _: 1
      }, 8, ["onConfirm"])
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}