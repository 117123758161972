export enum Permission {
  // This permission key helps determine whether or not the logged in user
  // should have permission to view the vpe. If not, then they need to not
  // even be alloed into the system.
  VPEAccess = 'vpe_access',
  // This permission key helps determine if this user has accepted the terms
  // or needs to accept the terms. This is a legal liability, so we want to
  // enforce this strictly.
  TermsAccepted = 'terms_accepted',
  // This permission key helps determine if the logged in user has deck access
  // or not. If it does then we will go ahead and make sure that we allow usage
  // of the deck platform.
  DeckAccess = 'deck_access'
}

/**
 * Define the roles for the application. The applicable values are the following,
 * super admin, account lead, company admin, etc.
 */
export enum Role {
  // Developer / Management Employees
  SuperAdmin = 'super_admin',

  // Client Facing Employees
  AccountLead = 'account_lead',

  // Clients who can use Editor
  CompanyAdmin = 'company_admin',

  // This is a convenience role to combine super admin and account lead
  // to make the code simpler to follow.
  InternalAdmin = 'internal_admin'
}

/**
 * Define the features that are allowed for the application. This is a mixture enum
 * which can contain all feature flags, so that we can expose it as a global property.
 */
export enum Feature {
  // Company flags
  // Whether or not an admin can put a password on a deck or not.
  DeckPassword = 'DECK_PASSWORD',
  AskAQuestion = 'ASK_A_QUESTION',
  BookAMeeting = 'BOOK_A_MEETING_ENABLED',
  ConnectScheduleMeeting = 'CONNECT_SCHEDULE_MEETING_ENABLED',
  DeckEmbeds = 'NEW_EMBED_DECKS_ENABLED',
  Access3DModelEditorEnabled = 'ACCESS_3D_MODEL_EDITOR_ENABLED',
  AccessNewDeckEditorEnabled = 'ACCESS_NEW_DECK_EDITOR_ENABLED',
  AccessVideoRecordingManagementEnabled = 'ACCESS_VIDEO_RECORDING_MANAGEMENT_ENABLED',
  ManageDrillLabelsEnabled = 'MANAGE_DRILL_LABELS_ENABLED',
  DoraEnabled = 'DORA_ENABLED',
  UploadDataFilesEnabled = 'DATA_FILE_UPLOAD_ENABLED'
}

/**
 * Define the types of payment plans in the application. This will allow us to set functionality
 * based on the payment plan without using strings.
 */
export enum SubscriptionTier {
  Gold = 'gold',
  Silver = 'silver'
}

/**
 * Export the type of resources that help manage access rights for various data. If we want to
 * support more access resources, just add to the list below and it will work automatically
 * through the service.
 */
export enum AccessResource {
  Project = 'project',
  Deck = 'deck'
}

/**
 * Define the types of access that a resource can have. View means that they cannot alter
 * any information with the selected resource, Edit means they have admin privaledges.
 */
export enum AccessType {
  None = 'none',
  View = 'view',
  Edit = 'edit'
}

/**
 * Define the type tags field for the deck model. These can be dynamically added through
 * various places in the application, so we need to make sure they are consistent.
 */
export enum DeckTypeTags {
  Investor = 'Investor',
  PressRelease = 'Press Release',
  SiteVisit = 'Virtual Site Visit',
  Technical = 'Technical',
  ESG = 'ESG'
}
