import { App } from 'vue'
// Components
import Form from '@/packages/form/components/Form.vue'
import FormGroup from '@/packages/form/components/FormGroup.vue'
import FormField from '@/packages/form/components/FormField.vue'
import FormFieldInline from '@/packages/form/components/FormFieldInline.vue'
import FormFieldSpacer from '@/packages/form/components/FormFieldSpacer.vue'
import DateInput from '@/packages/form/components/inputs/Date.vue'
import ShelfInput from '@/packages/form/components/inputs/Shelf.vue'
import ImageInput from '@/packages/form/components/inputs/Image.vue'
import Dropdown from '@/packages/form/components/dropdown/Dropdown.vue'

/**
 * This plugin will globalize the common form components  used throughout the application,
 * and apply any behaviour that might be necessary to get it installed correctly.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useForms(app: App): App {
  // base
  app.component('Form', Form)
  app.component('FormGroup', FormGroup)
  app.component('FormField', FormField)
  app.component('FormFieldInline', FormFieldInline)
  app.component('FormFieldSpacer', FormFieldSpacer)
  // inputs
  app.component('DateInput', DateInput)
  app.component('ShelfInput', ShelfInput)
  app.component('ImageInput', ImageInput)
  // dropdown
  app.component('Dropdown', Dropdown)
  return app
}
