import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    appear: "",
    show: _ctx.showing,
    as: "template"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        static: "",
        open: _ctx.showing,
        onKeydown: _withKeys(_ctx.esc, ["esc"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["inset-0 overflow-y-auto", [_ctx.position]]),
            style: {"z-index":"9999"}
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0",
                "enter-to": "opacity-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100",
                "leave-to": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogOverlay, { class: "dialog-overlay sticky inset-0 bg-gray-800 bg-opacity-75 transition-opacity" })
                ]),
                _: 1
              }),
              _cache[0] || (_cache[0] = _createElementVNode("span", {
                class: "hidden sm:inline-block sm:align-middle sm:h-screen",
                "aria-hidden": "true"
              }, "​", -1)),
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["inline-block w-full my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg relative", [_ctx.width]])
                  }, [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                  ], 2)
                ]),
                _: 3
              })
            ])
          ], 2)
        ]),
        _: 3
      }, 8, ["open", "onKeydown"])
    ]),
    _: 3
  }, 8, ["show"]))
}