import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "table--footer w-full flex items-center h-16",
  style: {"min-height":"4rem"}
}
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-gray-700 font-light"
}
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = {
  key: 1,
  class: "text-sm text-gray-700 font-light"
}
const _hoisted_8 = {
  key: 0,
  class: "ml-8 flex space-x-4"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.modelValue.count)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _cache[2] || (_cache[2] = _createTextVNode("Showing ")),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.modelValue.offset + 1), 1),
            _cache[3] || (_cache[3] = _createTextVNode(" to ")),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.modelValue.count <= _ctx.modelValue.offset + _ctx.modelValue.limit ? _ctx.modelValue.count : _ctx.modelValue.offset + _ctx.modelValue.limit), 1),
            _cache[4] || (_cache[4] = _createTextVNode(" of ")),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.modelValue.count), 1),
            _cache[5] || (_cache[5] = _createTextVNode(" Results"))
          ]))
        : (_openBlock(), _createElementBlock("label", _hoisted_7, "No Results Found"))
    ]),
    (!_ctx.onlyResults)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("button", {
            theme: "secondary",
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('previous'))),
            disabled: _ctx.modelValue.offset <= 1
          }, "Previous", 8, _hoisted_9),
          _createElementVNode("button", {
            theme: "secondary",
            size: "small",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('next'))),
            disabled: _ctx.modelValue.offset + _ctx.modelValue.limit >= _ctx.modelValue.count
          }, "Next", 8, _hoisted_10)
        ]))
      : _createCommentVNode("", true)
  ]))
}