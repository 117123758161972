import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/spinner.svg'


const _hoisted_1 = { id: "harness" }
const _hoisted_2 = { class: "bg-white opacity-75 fixed top-0 left-0 z-modal w-full h-full flex items-center content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "spinner" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "flex-1 text-center" }, [
            _createElementVNode("img", {
              class: "w-16 h-16 mx-auto",
              src: _imports_0
            })
          ], -1)
        ]), 512), [
          [_vShow, _ctx.state.working]
        ])
      ]),
      _: 1
    }),
    (!_ctx.state.loading)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}