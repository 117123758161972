import RestService from '@/modules/api/rest-service'
import { Model } from '@/modules/api/types'
// stores & services
import CompanyStore from '@/modules/api/stores/company'
// composables
import useAnalytics, { AnalyticsEvent } from '@/modules/common/composables/use-analytics'
import type { Deck } from '@/modules/api/types/deck'

export default new (class DeckRepository extends RestService<Deck> {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    if (!CompanyStore.$state.selected) {
      return ''
    }

    return `/companies/${CompanyStore.$state.selected.id}/decks`
  }

  protected get getEndpoint(): string {
    return '/decks'
  }

  /**
   * @override
   * @see RestService.stub
   */
  public stub(): Model {
    return {
      company_id: CompanyStore.$state.selected.id,
      title: null,
      content: null,
      date: null,
      is_pinned: false,
      view_access_type: 'private',
      type_tags: [],
      is_book_a_meeting_active: false,
      show_3d_menu: true,
      request_setting_id: null,
      request_setting: null
    }
  }

  /**
   * Returns a promise that when resolved will return a singular post model by its primary key.
   *
   * @param id {number} - the primary key for this record.
   * @param params {Map} - any additional parameters to include.
   * @return {Promise<Model>} - the direct response from the api.
   */
  public async find(id: number, params = {}): Promise<Deck> {
    return (await this.$axios.get(`${this.getEndpoint}/${id}`, { params })).data
  }

  /**
   * Returns a promise that when resolved will return a the current featured deck for a company.
   *
   * @return {Promise<Model>} - the direct response from the api.
   */
  public async featured(): Promise<Model> {
    const response = (
      await this.$axios.get(`/companies/${CompanyStore.$state.selected.id}/decks/featured`, { params: { include: ['assets'] } })
    ).data
    return response.results.length ? response.results[0] : null
  }

  /**
   * Returns a promise that when resolved will create a new record.
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the created model record from the api
   */
  public async create(model: Model): Promise<Model> {
    const deck = await super.create(model)
    useAnalytics().track(AnalyticsEvent.CreateDeck, {
      companyId: CompanyStore.$state.selected.id,
      deckId: deck.id
    })

    return deck
  }

  /**
   * Returns a promise that when resolved will update a new record.
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the updated model record from the api
   */
  public async update(model: Model): Promise<Model> {
    const deck = await super.update(model)
    useAnalytics().track(AnalyticsEvent.UpdateDeck, {
      companyId: CompanyStore.$state.selected.id,
      deckId: deck.id
    })

    return deck
  }

  /**
   * Clones a deck record and all associated assets.
   *
   * @param deck {Model} - the deck to clone.
   * @param payload {Model} - a payload to send to the api
   * @param options {any} - options to duplicate the model and drill data.
   * @return {Promise<void>}
   */
  public async clone(deck: Model, payload: Model, options: { duplicateModel?: boolean; duplicateDrillData?: boolean }): Promise<void> {
    const params = new URLSearchParams()
    if (options.duplicateModel) params.append('include', '3dmodel')
    if (options.duplicateDrillData) params.append('include', 'drill')
    const queryString = params.toString() ? `?${params.toString()}` : ''

    await this.$axios.post(`/decks/${deck.id}/clone${queryString}`, payload)
    useAnalytics().track(AnalyticsEvent.DuplicateDeck, {
      companyId: CompanyStore.$state.selected.id,
      deckId: deck.id
    })
  }
})()
