import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-transparent absolute top-4 right-3 cursor-pointer rounded-full p-2 transition-all duration-200", [_ctx.theme === 'danger' ? 'text-red-600 hover:bg-red-900' : 'text-gray-900 hover:bg-gray-300']])
  }, [
    _createVNode(_component_x_icon, { class: "h-6 w-6" })
  ], 2))
}