import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { class: "table--header" }
const _hoisted_3 = {
  class: "table--content flex-1 overflow-y-auto relative",
  style: {"max-height":"40rem"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "text-base truncate",
  style: {"max-width":"450px"}
}
const _hoisted_6 = { class: "font-light text-gray-700 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SortableColumnHeader = _resolveComponent("SortableColumnHeader")!
  const _component_TableColumn = _resolveComponent("TableColumn")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_PaginationFooter = _resolveComponent("PaginationFooter")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    static: "",
    onEsc: _ctx.cancel,
    width: "max-w-3xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, {
          onClick: _ctx.cancel,
          theme: _ctx.theme
        }, null, 8, ["onClick", "theme"]),
        _createVNode(_component_DialogTitle, {
          class: "text-lg font-medium leading-6 line-clamp-2 text-gray-900 capitalize",
          style: {"max-width":"90%"},
          as: "h3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.state.folder ? _ctx.state.folder.name : 'Select File'), 1)
          ]),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "Choose an existing file from the shelf.", -1))
      ]),
      _createVNode(_component_SearchBar, {
        modelValue: _ctx.state.pagination.query,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.pagination.query) = $event)),
        onUpdate: _ctx.query,
        placeholder: "Search Files..."
      }, null, 8, ["modelValue", "onUpdate"]),
      _createElementVNode("div", _hoisted_2, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "px-4 w-24 text-center" }, [
          _createElementVNode("h4", null, "THUMBNAIL")
        ], -1)),
        _createVNode(_component_SortableColumnHeader, {
          class: "flex-1",
          modelValue: _ctx.state.pagination.sort_by,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.pagination.sort_by) = $event)),
          column: "name",
          onChange: _ctx.sort
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("h4", null, "NAME", -1)
          ])),
          _: 1
        }, 8, ["modelValue", "onChange"]),
        _createVNode(_component_SortableColumnHeader, {
          class: "w-48 text-right",
          modelValue: _ctx.state.pagination.sort_by,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.pagination.sort_by) = $event)),
          column: "size",
          onChange: _ctx.sort
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createElementVNode("h4", null, "SIZE", -1)
          ])),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.files, (file) => {
          return (_openBlock(), _createBlock(_component_TableRow, {
            onRowClick: ($event: any) => (_ctx.select(file)),
            key: file.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TableColumn, { class: "px-4 w-24 text-center" }, {
                default: _withCtx(() => [
                  (file.thumbnail)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "mx-auto w-auto h-12 rounded-md",
                        src: file.thumbnail
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_TableColumn, { class: "flex-1 px-4" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(file.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_TableColumn, { class: "w-48 px-4 text-right" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$filters.fileSize(file.size)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onRowClick"]))
        }), 128))
      ]),
      _createVNode(_component_PaginationFooter, {
        modelValue: _ctx.state.pagination,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.pagination) = $event)),
        onNext: _ctx.next,
        onPrevious: _ctx.previous
      }, null, 8, ["modelValue", "onNext", "onPrevious"])
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}