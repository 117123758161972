import { Axios } from '@/modules/api/axios'
import { Model, QueryResponse } from '@/modules/api/types'
import { AccessResource } from '@/modules/api/enums'

export default new (class SecureLinkService extends Axios {
  /**
   * This method will pull all secure links for the passed resource and resource type. This will
   * return an listing of links rather than any kind of other data.
   *
   * @param resource {Model} - the resource to pull data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   * @return {Promise<QueryResponse>}
   */
  public async query(resource: Model, resourceType: AccessResource): Promise<QueryResponse> {
    return (await this.$axios.get(`/${resourceType}s/${resource.id}/uuids/`)).data
  }

  /**
   * Create a secure link which is applied ot the passed resource. This will allow us to share
   * access to a specific resource given a list of recipients, and values.
   *
   * @param model {Model} - the params to send to create.
   * @param resource {Model} - the resource to create data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   */
  public async create(model: Model, resource: Model, resourceType: AccessResource): Promise<Model> {
    return (await this.$axios.post(`/${resourceType}s/${resource.id}/uuids/`, model)).data
  }

  /**
   * Delete a secure link related to the passed resource, model, and resource type. This will allow
   * us to go ahead and revoke any access to a specific resource for the data originally passed.
   *
   * @param model {Model} - the params to send to destroy.
   * @param resource {Model} - the resource to destroy data for.
   * @param resourceType {AccessResource} - the type of resource this is pulling.
   */
  public async destroy(model: Model, resource: Model, resourceType: AccessResource): Promise<Model> {
    return (await this.$axios.delete(`/${resourceType}s/${resource.id}/uuids/${model.id}`)).data
  }
})()
