import axios, { AxiosError, AxiosInstance } from 'axios'

/**
 * Create a new instance of the axios client, and append the default
 * api url so that we can duck-type all the remaining api endpoints.
 */
export function createAxiosInstance(): AxiosInstance {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_ENDPOINT}/v2`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-application': 'cms'
    }
  })
}

/**
 * @param e An error returned from the API
 * @returns a boolean typecheck - useful for satisfying TypeScript
 */
export function isAxiosError(e: unknown): e is AxiosError<any> {
  return (<any>e).isAxiosError ?? false
}
