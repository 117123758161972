import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { class: "flex items-center justify-start space-x-4 p-6" }
const _hoisted_3 = { class: "flex-1 flex items-center justify-end" }
const _hoisted_4 = { class: "flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Cropper = _resolveComponent("Cropper")!
  const _component_ZoomInIcon = _resolveComponent("ZoomInIcon")!
  const _component_ZoomOutIcon = _resolveComponent("ZoomOutIcon")!
  const _component_ReplyIcon = _resolveComponent("ReplyIcon")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: "max-w-3xl max-h-3xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.closable)
          ? (_openBlock(), _createBlock(_component_DialogCloseButton, {
              key: 0,
              onClick: _ctx.cancel,
              theme: "primary"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_DialogTitle, {
          class: "text-xl font-medium leading-6 line-clamp-2 text-gray-900 capitalize",
          as: "h3"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Edit Image")
          ])),
          _: 1
        }),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "Manipulate and crop your image below using the controls in the bottom left.", -1))
      ]),
      _createVNode(_component_Cropper, {
        class: "h-auto w-auto",
        ref: "CropperRef",
        src: _ctx.state.url,
        "stencil-props": { aspectRatio: _ctx.aspectRatio },
        "default-size": _ctx.fillArea,
        onChange: _ctx.change
      }, null, 8, ["src", "stencil-props", "default-size", "onChange"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          theme: "secondary",
          size: "small",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.zoom(2)), ["prevent"]))
        }, [
          _createVNode(_component_ZoomInIcon, { class: "text-gray-700 w-5 h-5" })
        ]),
        _createElementVNode("button", {
          theme: "secondary",
          size: "small",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.zoom(0.5)), ["prevent"]))
        }, [
          _createVNode(_component_ZoomOutIcon, { class: "text-gray-700 w-5 h-5" })
        ]),
        _createElementVNode("button", {
          theme: "secondary",
          size: "small",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.rotate(90)), ["prevent"]))
        }, [
          _createVNode(_component_ReplyIcon, {
            class: "text-gray-700 w-5 h-5",
            style: {"transform":"scaleX(-1)"}
          })
        ]),
        _createElementVNode("button", {
          theme: "secondary",
          size: "small",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.rotate(-90)), ["prevent"]))
        }, [
          _createVNode(_component_ReplyIcon, { class: "text-gray-700 w-5 h-5" })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            theme: "secondary",
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)), ["prevent"]))
          }, "Cancel")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            theme: "copper",
            onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
          }, "Save")
        ])
      ])
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}