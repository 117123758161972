import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
// utilities
// stores & services
import SessionStore from '@/modules/api/stores/session'
import CompanyStore from '@/modules/api/stores/company'
import SessionService from '@/modules/api/services/session'
import CompanyRepository from '@/modules/api/repositories/company'
// types
import { Role, Permission } from '@/modules/api/enums'
import { loginWithRedirect, pushToWebApp } from '@/modules/application/router/router.utilities'
import { isMobileBrowser, isSupportedBrowser } from '@/modules/common/browser.utilities'

/** @type {string[]} - the routes that are not protected */
const unprotectedRoutes: string[] = [
  'login',
  'recover',
  'terms',
  'status',
  'unsupported-browser',
  'reset',
  'activate',
  'error--403',
  'error--404',
  'maintenance'
]

/** @type {string[]} - the routes that result in the company being chosen automatically. */
const companySpecificRoutes: string[] = ['site--editor', 'deck--editor', 'site--list']

/**
 * Before we route to the next step, we need to go through the following checks to make sure
 * this user can access all of the content. If any of the following fail, then we need to redirect
 * to the correct location.
 *
 * 1. Check to see if the session is valid (and populate from cache)
 * 2. Check to see if the user has accepted terms
 * 3. Check to see if the user has vpe access (correct permissions)
 *
 * @param to {RouteLocationNormalized}
 * @param from {RouteLocationNormalized}
 * @param next {NavigationGuardNext}
 */
export default async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<any> => {
  // We need to check to see if this is an unsupported browser, and if it is display the route
  // that will display the browser information being unsupported.
  if ((isMobileBrowser() || !isSupportedBrowser()) && to.name !== 'unsupported-browser') {
    return next({ name: 'unsupported-browser' })
  }

  // Ignore any public routes so that we dont end up running all of the
  // following permission checks.
  if (unprotectedRoutes.includes(to.name as string)) {
    return next()
  }

  // Now that we are on to non-public routes, we need to check whether there is
  // a user, otherwise try to initialize one.
  if (!SessionStore.$state.user) {
    await SessionService.initialize(to.fullPath)
    // if we still don't have a user after initializing, back to login
    // Note that the refresh call is also pushing to login, but if we don't return here
    // then the next check will push back to the web app.
    if (!SessionStore.$state.user) {
      loginWithRedirect(to.fullPath)
      return next(false)
    }
  }

  // If the maintenance switch is on, take the user to the maintenance page
  if (SessionStore.$state.switches.includes('MAINTENANCE')) {
    return next({ name: 'maintenance' })
  }

  // If the logged in user does not have access to the vpe then we want to
  // throw the user back to the web app.
  if (!SessionStore.hasPermission(Permission.VPEAccess)) {
    pushToWebApp()
    return next(false)
  }

  // If the logged in user has not accepted the terms and conditions of the application
  // then we need to throw them to this screen to force it.
  if (!SessionStore.hasPermission(Permission.TermsAccepted) && to.name !== 'terms') {
    return next({ name: 'terms' })
  }

  const companies = (await CompanyRepository.query({ limit: 2, access_type: 'edit', include: ['payment_plan', 'last_quote'] })).results

  // No companies, shouldn't be in the Editor, push to web app url
  if (companies.length === 0) {
    pushToWebApp()
    return next(false)
  }

  // Make sure that there is a company selected so that no calls will fail during the
  // processing of the actual application.
  // If there is no company selected -- things like the company dashboard will get jacked (wont load).
  // If the to route is a "company specific route" that enforces company selection, not need to force
  // selection here.
  if (!CompanyStore.$state.selected && to.name !== 'company--list' && !companySpecificRoutes.includes(to.name as string)) {
    // One company select it
    if (companies.length === 1) {
      await CompanyStore.select(companies[0])
      return next()
    }

    // More than 1 company, go to companies list
    return next({ name: 'company--list' })
  }

  // If the user is trying to access decks, and does not have the permission to do so,
  // then we want to go ahead and redirect back to the index, and not even allow them to view
  // the decks page.
  const isInternalAdmin = SessionStore.hasRole(Role.InternalAdmin)
  if (to.name === 'deck--list' && !isInternalAdmin && !SessionStore.hasPermission(Permission.DeckAccess)) {
    return from
  }

  // If we get to this point, all of the checks have passed and we can move to the next
  // stage in instantiation of the application.
  return next()
}
