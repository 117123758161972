import RestService from '@/modules/api/rest-service'
import { Model } from '@/modules/api/types'
// stores & services
import CompanyStore from '@/modules/api/stores/company'

export default new (class AdminRepository extends RestService {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    if (!CompanyStore.$state.selected) {
      return ''
    }

    return `/companies/${CompanyStore.$state.selected.id}/admins`
  }

  /**
   * @override
   * @see RestService.stub
   */
  public stub(): Model {
    return {
      name: null,
      email_address: null
    }
  }

  /**
   * Assigns the passed user to the company, and grants permission to see
   * company information, data, etc.
   *
   * @param user {Model} - the user to assign.
   * @param company {Model} - the company to assign
   * @return {Promise<void>}
   */
  public async assign(user: Model, company?: Model): Promise<void> {
    const c = company || CompanyStore.$state.selected.id
    await this.$axios.post(`/companies/${c.id}/admins/${user.id}/assign`)
  }

  /**
   * Unassigns the passed user to the company, and grants permission to see
   * company information, data, etc.
   *
   * @param user {Model} - the user to unassign.
   * @param company {Model} - the company to unassign
   * @return {Promise<void>}
   */
  public async unassign(user: Model, company?: Model): Promise<void> {
    const c = company || CompanyStore.$state.selected.id
    await this.$axios.post(`/companies/${c.id}/admins/${user.id}/unassign`)
  }
})()
