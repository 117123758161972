import { Axios } from '@/modules/api/axios'
import { Model, QueryResponse } from '@/modules/api/types'

export default abstract class RestService<T = any> extends Axios {
  /**
   * Enforce the usage of the getter to list the endpoint for child service
   * classes. This will allow us to enforce that there is always an endpoint set
   * in each implementation (like an interface).
   *
   * @abstract
   * @return {string}
   */
  protected abstract get endpoint(): string

  //
  // endpoint methods
  //

  /**
   * Return a stub model object (in this case a blank object), and allow the implementation
   * to override this and return specific properties that should be there.
   *
   * @return {Model}
   */
  public stub(): Model {
    return {}
  }

  /**
   * Returns a promise that when resolved will return a list of post models returned
   * from the api. This will also update the internal state, updating bound components.
   *
   * @param params {QueryRequest} - the params to filter there result set by.
   * @return {Promise<QueryResponse>} - the direct response from the api.
   */
  public async query(params?: any): Promise<QueryResponse<T>> {
    const response = await this.$axios.get(`${this.endpoint}`, { params })
    if (!response.data || !('results' in response.data)) {
      return { results: [], count: 0 }
    }
    return response.data
  }

  /**
   * Returns a promise that when resolved will return a singular post model by its primary key.
   *
   * @param id {number} - the primary key for this record.
   * @param params {Map} - any additional parameters to include.
   * @return {Promise<Model>} - the direct response from the api.
   */
  public async find(id: number | string, params = {}): Promise<T> {
    return (await this.$axios.get(`${this.endpoint}/${id}`, { params })).data
  }

  /**
   * Returns a promise that when resolved will create a new record.
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the created model record from the api
   */
  public async create(model: Model): Promise<Model> {
    return (await this.$axios.post(`${this.endpoint}`, model)).data
  }

  /**
   * Returns a promise that when resolved will batch create the records.
   *
   * @param models {Model[]} - the array of models to create.
   * @return {Promise<Model[]>} - the created model records from the api
   */
  public async bulkCreate(models: Model[]): Promise<Model[]> {
    return (await this.$axios.post(`${this.endpoint}`, models)).data
  }

  /**
   * Returns a promise that when resolved will update a new record.
   *
   * @param model {Model} - the model to update.
   * @return {Promise<Model>} - the updated model record from the api
   */
  public async update(model: Model): Promise<Model> {
    return (await this.$axios.put(`${this.endpoint}/${model.id}`, model)).data
  }

  /**
   * Returns a promise that when resolved will batch update the records.
   *
   * @param models {Model[]} - the models to batch update.
   * @return {Promise<Model[]>} - the updated model records from the api
   */
  public async bulkUpdate(models: Model[]): Promise<Model[]> {
    return (await this.$axios.put(`${this.endpoint}`, models)).data
  }

  /**
   * Returns a promise that when resolved will partially update a new record based on
   * the minor parameters that are passed.
   *
   * @param partialModel {Model} - the model to partially update.
   * @return {Promise<Model>} - the updated model record from the api
   */
  public async patch(partialModel: Model): Promise<Model> {
    return (await this.$axios.patch(`${this.endpoint}/${partialModel.id}`, partialModel)).data
  }

  /**
   * Returns a promise that when resolved will partially update a batch of records based on
   * the minor parameters that are passed.
   *
   * @param partialModels {Model[]} - the models to partially update.
   * @return {Promise<Model>} - the updated model records from the api
   */
  public async bulkPatch(partialModels: Model[]): Promise<Model[]> {
    return (await this.$axios.patch(`${this.endpoint}`, partialModels)).data
  }

  /**
   * This is a convenience method which will allow us not to do method switching between
   * create and update in component functions.
   *
   * @param model {Model} - the model to either save or update.
   * @return {Promise<void>}
   */
  public async save(model: Model): Promise<Model> {
    return 'id' in model && model.id !== null ? this.update(model) : this.create(model)
  }

  /**
   * Returns a promise that when resolved will delete the record with the passed id.
   *
   * @param model {Model} - the model to destroy.
   * @return {Promise<void>}
   */
  public async destroy(model: Model): Promise<void> {
    return this.$axios.delete(`${this.endpoint}/${model.id}`)
  }

  /**
   * Returns a promise that when resolved will batch delete the records with the passed ids.
   *
   * @param ids {number[]} - the array of ids to destroy.
   * @return {Promise<void>}
   */
  public async bulkDestroy(ids: number[]): Promise<void> {
    return this.$axios.delete(`${this.endpoint}`, { data: ids.map(id => ({ id })) })
  }
}
