import { Axios } from '@/modules/api/axios'
import { Switch } from '@/modules/api/types/switches'

export default new (class SwitchesService extends Axios {
  /**
   * Return the switches associated to the current logged in users account. The flags
   * will be determined based on the session token provided internally on the request.
   *
   * @return {Promise<void>}
   */
  public async query(): Promise<Switch[]> {
    return (await this.$axios.get('/switches')).data
  }
})()
