<template>
  <article>
    <SectionTitle><slot name="title"></slot></SectionTitle>
    <Description><slot name="description"></slot></Description>
    <Form>
      <pre class="break-words whitespace-pre-wrap p-4" ref="CodeRef"><slot></slot></pre>
    </Form>
    <slot name="disclaimer"></slot>
    <slot name="action" :copied="copied" :copyCode="copyCode">
      <CopyButton :copied="copied" @click="copyCode"></CopyButton>
    </slot>
  </article>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue'
import useCopyCode from '@/modules/common/composables/use-copy-code'
import { Notification } from '@/packages/notification'

const SectionTitle = defineComponent({
  name: 'Title',
  template: `<p class="mb-1 text-sm font-medium text-gray-900 leading-5"><slot /></p>`
})

const Description = defineComponent({
  name: 'Description',
  template: `<p class="mb-1 text-sm font-normal text-gray-800 leading-5"><slot /></p>`
})

const Form = defineComponent({
  name: 'Form',
  template: `
    <div class="pb-3 text-sm text-gray-900">
      <form class="text-gray-900 border border-gray-200 rounded-lg ">
        <slot />
      </form>
    </div>
  `
})

const CopyButton = defineComponent({
  name: 'CopyButton',
  template: `
    <div class="flex items-center justify-end pb-6 space-x-4">
      <button @click="$emit('click')" theme="secondary">
	      {{ copied ? 'Copied' : 'Copy To Clipboard' }}
      </button>
    </div>
  `,
  props: {
    copied: {
      type: Boolean,
      required: true
    }
  },
  emits: ['click']
})

export default defineComponent({
  name: 'CopyCode',

  components: { SectionTitle, Description, Form, CopyButton },

  props: {
    notification: {
      type: Object as PropType<Pick<Notification, 'title' | 'message'>>,
      required: true
    }
  },

  emits: ['copied'],

  setup(props, { emit }) {
    const CodeRef: any = ref(null)
    const { copied, copyCode } = useCopyCode({ notification: props.notification, onCodeCopied: () => emit('copied') })

    return { copied, CodeRef, copyCode: () => copyCode(CodeRef.value.textContent) }
  }
})
</script>
