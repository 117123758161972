import { App } from 'vue'
import * as Sentry from '@sentry/vue'

/**
 * This plugin will initialize the sentry error tracking functionality throughout
 * the application. This will *only* be applicable if you are not working locally.
 *
 * @note See the vue.config.js for other settings and functionality that is implemented that
 *       cannot go in the application.
 * @see https://sentry.io/for/vue/
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useSentry(app: App<Element>): App {
  if (process.env.NODE_ENV !== 'development') {
    try {
      Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration() as any],
        release: `vpe@${process.env.VUE_APP_VERSION}`,
        environment: process.env.VUE_APP_SENTRY_ENV
      } as Sentry.BrowserOptions)

      Sentry.attachErrorHandler(app, { logErrors: true } as any)

      app.config.errorHandler = (err: any, _: any, info: any) => {
        Sentry.setTag('info', info)
        Sentry.captureException(err)
      }

      window.addEventListener('error', event => {
        Sentry.captureException(event)
      })

      window.addEventListener('unhandledrejection', event => {
        Sentry.captureException(event)
      })
    } catch {
      /** do nothing */
    }
  }

  return app
}
