import { Axios } from '@/modules/api/axios'
// v3 stores & services
import FeatureFlagService from '@/modules/api/services/feature-flag'
import SwitchesService from '@/modules/api/services/switches'
import ApplicationStore from '@/modules/api/stores/application'
import SessionStore from '@/modules/api/stores/session'
import CompanyStore from '@/modules/api/stores/company'
import CompanyRepository from '@/modules/api/repositories/company'
// types & enums
import { Role, Permission } from '@/modules/api/enums'
// utilities
import { decodeUserId } from '@/modules/api/jwt.utilities'
import useAnalytics from '@/modules/common/composables/use-analytics'

export default new (class SessionService extends Axios {
  /**
   * Initialize an existing session from stored localStorage information, as well as
   * a lot of other information.
   *
   * @param redirectPath {string} - the path to redirect back after login if the initialize fails
   * @return {Promise<void|null>}
   */
  public async initialize(redirectPath = ''): Promise<void | null> {
    // We need to get an access token from the refresh endpoint (if we have a refresh cookie)
    // otherwise it will push to login
    await SessionStore.refresh(redirectPath)

    // Store the session data, cookies, and build out any permissions or other
    // necessary information to maintain application state.
    if (SessionStore.$state.accessToken) {
      await this.bootstrap()
    }
  }

  /**
   * Destroy the currently stored session information, and any cookies, user info
   * or anything else that is currently being used to manage state.
   *
   * @return {Promise<void>}
   */
  public async destroy(): Promise<void> {
    ApplicationStore.resetStores()
    await this.$axios.post('/auth/logout', {}, { withCredentials: true })
    useAnalytics().reset()
  }

  //
  // private
  //

  /**
   * Create session information in the various locations so that we can go ahead and keep state
   * between tabs and other stuff.
   */
  private async bootstrap(): Promise<void> {
    await this.createUserState()
    await CompanyStore.createCompanyState()
    await this.createSwitchesState()

    // Refresh company so that cdn links aren't expired.
    try {
      if (CompanyStore.$state.selected) {
        const refreshedCompany = await CompanyRepository.find(CompanyStore.$state.selected.id, {
          include: ['payment_plan', 'last_quote', 'show_bam_company_button', 'assets']
        })
        await CompanyStore.select(refreshedCompany)
      }
    } catch {
      // Permissions revoked – fall through
      window.localStorage.removeItem('v2.company')
      window.location.reload()
    }
    await this.createPermissionsState()
    useAnalytics().identify(SessionStore.$state.user)
  }

  /**
   * Determine if the user who has logged in is the same as the user in local storage.
   * If not, reset the stores and set up new user.
   */
  private async createUserState(): Promise<void> {
    // See if we can get user from localStorage
    const user = JSON.parse(window.localStorage.getItem('v2.user') as string)
    if (user) {
      if (user.id !== decodeUserId(SessionStore.$state.accessToken)) {
        // The storage user is different than the user we got from the access token
        // Destroy session and refresh
        ApplicationStore.resetStores()
        await SessionStore.refresh()
      }
    }

    // Initialize the vue 3 store state
    SessionStore.user = (await this.$axios.get('/user')).data
    window.localStorage.setItem('v2.user', JSON.stringify(SessionStore.$state.user))
    await Promise.all([this.createRequestSettingState(), this.createFlagState()])
  }

  public async createRequestSettingState() {
    SessionStore.requestSetting = (await this.$axios.get('/meetings/request-settings')).data?.[0]
  }

  public async createFlagState() {
    const userFlags = (await FeatureFlagService.query()).filter(flag => flag.is_active_for_user).map(flag => flag.name) as string[]
    SessionStore.userFeatureFlags = userFlags
  }

  public async createSwitchesState() {
    const switches = (await SwitchesService.query()).filter(s => s.active).map(s => s.name)
    SessionStore.switches = switches
  }

  /**
   * Initialize the session permissions for the current logged in user, and apply
   * any overrides necessary for various roles (account lead, super admin, etc)
   *
   * @return {Promise<void>}
   */
  private async createPermissionsState(): Promise<void> {
    // Check if the user has a role that allows editor access, this lets us boot
    // users before we get to the check if they have an editable company (saves some API calls).
    if (!SessionStore.hasPermission(Permission.VPEAccess)) {
      return
    }

    // Internal admins are exempt from the terms & conditions and always have deck access.
    // Non-internal admins will be checked for deck access based on company payment plan.
    if (SessionStore.hasRole(Role.InternalAdmin)) {
      SessionStore.addPermission(Permission.TermsAccepted)
      SessionStore.addPermission(Permission.DeckAccess)
      return
    }

    // By default, this is a company admin user.
    // Check to see if this user has accepted the terms and agreements of the vpe,
    // and if so, we add a dummy permission so that we can check against it.
    try {
      const response = await this.$axios.get('/admin/terms')
      if (response.data === true) {
        SessionStore.addPermission(Permission.TermsAccepted)
      }
    } catch {
      // Were squelching the error here because we do not want to trip up the bootup
      // logic. All this does is control whether or not they have that permission,
      // it should not affect the flow.
    }
  }
})()
