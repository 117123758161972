// types and enums
// libraries
import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/browser'
import { Model } from '@/modules/api/types'

/**
 * Define the various events and their key names through a common
 * enum structure so that it can be exported appropriately.
 */
export enum AnalyticsEvent {
  EditorLogin = 'editorLogin',
  CreateDeck = 'createDeck',
  CreateAsset = 'createAsset',
  DuplicateDeck = 'duplicateDeck',
  UpdateDeck = 'updateDeck',
  UpdateAsset = 'updateAsset',
  PDFSlidesReplaced = 'pdfSlidesReplaced',
  CreateSecureLink = 'createSecureLink',
  SendSecureLink = 'sendSecureLink',
  SiteViewerModeToggle = 'siteViewerModeToggle',

  // deck updates
  LegacyDeckEditorAddASlideClickCreate = 'LegacyDeckEditorAddASlideClickCreate',
  LegacyDeckEditorReorderSlidesClickDragHandle = 'LegacyDeckEditorReorderSlidesClickDragHandle',
  LegacyDeckEditorDuplicateSlideClickConfirm = 'LegacyDeckEditorDuplicateSlideClickConfirm',
  LegacyDeckEditorDeleteSlideClickConfirm = 'LegacyDeckEditorDeleteSlideClickConfirm',
  LegacyDeckEditorPageLoad = 'LegacyDeckEditorPageLoad',

  // embed link copying
  CopiedEmbedCode = 'copiedEmbedCode',
  CopiedDeckEmbedCode = 'copiedDeckEmbedCode',
  CopiedDeckSlideEmbedCode = 'copiedDeckSlideEmbedCode',
  EmbedClickSwitchToAnimated = 'EmbedClickSwitchToAnimated',

  // Inbox
  InboxClickFilter = 'InboxClickFilter',
  InboxPageLoad = 'InboxPageLoad',
  InboxClickMessageInList = 'InboxClickMessageInList',
  InboxClickSendMessage = 'InboxClickSendMessage',
  InboxClickEditMessage = 'InboxClickEditMessage',
  InboxClickDeckSlideReference = 'InboxClickDeckSlideReference'
}

/**
 * This is a componsable mixin function that will allow interaction with the
 * analytics platforms (amplitude, mixpanel, etc).
 *
 * @return {any} - a mix of objects and functions.
 */
export default (): any => {
  // Initialize the amplitude library through instance creation so that we can tie
  // in event tracking to their wrapper library.
  const $amplitude = amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY, null, {
    platform: 'editor'
  })

  /**
   * Track a user behaviour or action using the passed analytics event name as well as
   * a specific (and loose) payload that will be sent with the original event logging.
   *
   * @param event {AnalyticsEvent} - the event to track.
   * @param meta {Model} - a meta payload model to track.
   */
  function track(event: AnalyticsEvent, meta: Model): void {
    try {
      // track with amplitude
      amplitude.getInstance().logEvent(event, meta)
    } catch (error) {
      // Capture any errors that are possible, and throw them to sentry for tracking.
      Sentry.captureException(error)
    }
  }

  /**
   * Identify the current logged in state, as well as as handle any property setting
   * so that we can keep the information consistent with the current session.
   *
   * @param user {Model} - the user to identify.
   * @return {void}
   */
  function identify(user: Model): void {
    // set the amplitude tracking properties.
    amplitude.getInstance().setUserId(user.id)
    amplitude.getInstance().setUserProperties(user)
  }

  /**
   * Reset the tracking information for the current session so that we can make sure
   * that any tracking that happens does not carry stale user data.
   *
   * @return {void}
   */
  function reset(): void {
    // reset the amplitude properties
    amplitude.getInstance().setUserId(null)
    amplitude.getInstance().regenerateDeviceId()
  }

  return {
    AnalyticsEvent,
    $amplitude,
    track,
    identify,
    reset
  }
}
