import { jwtDecode } from 'jwt-decode'
import { Model } from '@/modules/api/types'

/**
 * Export the wrapped decode function. This will likely not be used directly,
 * but will provide a common wrapper for subsequent functions.
 *
 * @param token {string} - the jwt token to decode
 * @return Model
 */
export function decode(token: string): Model {
  try {
    return jwtDecode(token)
    // If the token fails to decode for some reason, then we need to go ahead
    // and return a stubbed object. These defaults will still fail the validation
    // causing good behaviour, we just need some fallback.
  } catch {
    return {
      id: null,
      exp: 0
    }
  }
}

/**
 * Return the user id from a jwt token.
 *
 * @param token {string}
 * @return {number}
 */
export function decodeUserId(token: string): number {
  return decode(token).id
}

/**
 * Return the expiry from a jwt token - converted to JS time.
 *
 * @param token {string}
 * @return {number}
 */
export function decodeExpiration(token: string): number {
  return decode(token).exp * 1000
}

/**
 * Return true if we have an access token that isn't expired, false otherwise
 *
 * @return {boolean}
 */
export function isTokenExpired(token: string): boolean {
  // If there is no token passed then it automatically fails the validation,
  // and we return that it has expired.
  if (!token) {
    return true
  }

  // Check the expiration time against a generic modifier to see if the token timeframe has
  // expired and therefore a new token should be issued.
  return decodeExpiration(token) - Date.now() < 30000
}
