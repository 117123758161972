import RestService from '@/modules/api/rest-service'
import CompanyStore from '@/modules/api/stores/company'
import SessionStore from '@/modules/api/stores/session'
// types
import { Model, QueryRequest, QueryResponse, QuoteHistoryQueryResponse } from '@/modules/api/types'
import { Role, SubscriptionTier } from '@/modules/api/enums'

export default new (class CompanyRepository extends RestService {
  /**
   * Return the endpoint for this service mechanism. Due to it being a getter, it will
   * allow us to "lazy-load" the endpoint.
   *
   * @override
   * @see RestService.endpoint
   * @return {string}
   */
  protected get endpoint(): string {
    return '/companies'
  }

  /**
   * @override
   * @see RestService.stub
   */
  public stub(): Model {
    return {
      name: null,
      description: null,
      email_address: null,
      status: 'U',
      stock_symbol: null,
      website: null,
      is_vrified: false,
      is_internal: false,
      is_featured: false,
      last_quote: null,
      show_bam_company_button: false,
      payment_plan: {
        project_count: 1,
        plan_tier: 'gold',
        initial_start_date: new Date(),
        is_annual_subscription: false,
        is_subscription_active: false
      }
    }
  }

  /**
   * Returns a promise that when resolved will create a new record.
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the created model record from the api
   */
  public async create(model: Model): Promise<Model> {
    const company = await super.create(model)
    // Dont send the payment plans request if there is none when the company
    // is being created. This will prevent internal errors from occuring on various states.
    if (model.payment_plan?.plan_tier && Object.values(SubscriptionTier).includes(model.payment_plan.plan_tier.toLowerCase())) {
      company.payment_plan = (await this.$axios.post(`/companies/${company.id}/payment-plans`, model.payment_plan)).data
    }

    return company
  }

  /**
   * Returns a promise that when resolved will update a new record.
   *
   * @param model {Model} - the model to create.
   * @return {Promise<Model>} - the updated model record from the api
   */
  public async update(model: Model): Promise<Model> {
    const company = await super.update(model)

    // Only issue the update if the current session user is an internal admin
    // (account lead or super admin). Company admins cannot currently update payment plan
    // information due to changing requirements.
    if (SessionStore.hasRole(Role.InternalAdmin)) {
      // If there is no payment plan information, then we delete the payment
      // plan from the company.
      if ((!model.payment_plan || !model.payment_plan.plan_tier) && model.payment_plan.created_at) {
        await this.$axios.delete(`/companies/${company.id}/payment-plans`)
        return company
      }
      // Were keying off of the created_at to see if there is a payment plan that exists. The
      // id is not returned in the response, so we have to find a unique field which would be
      // the created_at timestamp.
      if (model.payment_plan && model.payment_plan.plan_tier) {
        const method = model.payment_plan && model.payment_plan.created_at ? 'put' : 'post'
        company.payment_plan = (await this.$axios[method](`/companies/${company.id}/payment-plans`, model.payment_plan)).data
      }
    }

    return company
  }

  /**
   * Returns a promise that when resolved returns a list of assets for the associated
   * company that is currently selected.
   *
   * @param params {QueryRequest} - the params to filter there result set by.
   * @return {Promise<[]>} - the direct response from the api.
   */
  public async assets(params?: QueryRequest): Promise<QueryResponse> {
    return (await this.$axios.get(`${this.endpoint}/${CompanyStore.$state.selected.id}/assets`, { params })).data
  }

  /**
   * Returns a promise that when resolved returns a list of stocks for the associated
   * company that is currently selected.
   *
   * @param params {QueryRequest} - the params to filter there result set by.
   * @return {Promise<[]>} - the direct response from the api.
   */
  public async stocks(params?: QueryRequest): Promise<QuoteHistoryQueryResponse> {
    return (await this.$axios.get(`${this.endpoint}/${CompanyStore.$state.selected.id}/quote-history`, { params })).data
  }

  /**
   * Returns a promise that when resolved returns a financial information for
   * the associated company that is currently selected
   * @param params
   * @returns {Promise<{}>} - the direct response from the api
   */
  public async capitalStructure(): Promise<QueryResponse> {
    return (await this.$axios.get(`${this.endpoint}/${CompanyStore.$state.selected.id}/capital-structure/latest`)).data
  }

  /**
   * Returns a promise that when resolved will return a list of book-a-meeting settings
   * returned from the api.
   *
   * @param params {QueryRequest} - the params to filter there result set by.
   * @return {Promise<QueryResponse>} - the direct response from the api.
   */
  public async meetingSettings(params?: QueryRequest): Promise<Model[]> {
    return (await this.$axios.get(`/companies/${CompanyStore.$state.selected.id}/request-settings`, { params })).data
  }
})()
