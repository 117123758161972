import { App } from 'vue'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'

/**
 * This plugin will globalize the headless ui components  used throughout the application
 * so that we can not have to bloat our controller code with icon imports.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useHeadlessUI(app: App): App {
  // popover menu
  app.component('Menu', Menu)
  app.component('MenuButton', MenuButton)
  app.component('MenuItems', MenuItems)
  app.component('MenuItem', MenuItem)
  // dialogs
  app.component('TransitionRoot', TransitionRoot)
  app.component('TransitionChild', TransitionChild)
  app.component('Dialog', Dialog)
  app.component('DialogOverlay', DialogOverlay)
  app.component('DialogTitle', DialogTitle)
  // switches
  app.component('Switch', Switch)
  app.component('SwitchGroup', SwitchGroup)
  app.component('SwitchLabel', SwitchLabel)
  // disclosure
  app.component('Disclosure', Disclosure)
  app.component('DisclosureButton', DisclosureButton)
  app.component('DisclosurePanel', DisclosurePanel)
  // listbox
  app.component('Listbox', Listbox)
  app.component('ListboxButton', ListboxButton)
  app.component('ListboxOptions', ListboxOptions)
  app.component('ListboxOption', ListboxOption)

  return app
}
