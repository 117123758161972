import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "break-words whitespace-pre-wrap p-4",
  ref: "CodeRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_Description = _resolveComponent("Description")!
  const _component_Form = _resolveComponent("Form")!
  const _component_CopyButton = _resolveComponent("CopyButton")!

  return (_openBlock(), _createElementBlock("article", null, [
    _createVNode(_component_SectionTitle, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _: 3
    }),
    _createVNode(_component_Description, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "description")
      ]),
      _: 3
    }),
    _createVNode(_component_Form, null, {
      default: _withCtx(() => [
        _createElementVNode("pre", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ], 512)
      ]),
      _: 3
    }),
    _renderSlot(_ctx.$slots, "disclaimer"),
    _renderSlot(_ctx.$slots, "action", {
      copied: _ctx.copied,
      copyCode: _ctx.copyCode
    }, () => [
      _createVNode(_component_CopyButton, {
        copied: _ctx.copied,
        onClick: _ctx.copyCode
      }, null, 8, ["copied", "onClick"])
    ])
  ]))
}