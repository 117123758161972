import { reactive, readonly } from 'vue'
import { cloneDeep } from 'lodash'
import { State } from '@/modules/api/types'

export default abstract class Store<T extends Record<string, unknown>> {
  /** @type {State} */
  protected originalState: T

  /** @type {State} */
  protected state: T

  /** @constructor */
  protected constructor(state: State) {
    this.originalState = cloneDeep(state) as T
    this.state = reactive(state) as T
  }

  /**
   * Computed getter for retrieving internal state.
   *
   * @return {State} - the current state for this instant.
   */
  public get $state(): State {
    return readonly(this.state) as T
  }

  /**
   * Resets the state back to the original values. This will allow us to hard
   * reset a store very quickly and will wipe out any state.
   *
   * @return {void}
   */
  public reset(): void {
    this.state = reactive(cloneDeep(this.originalState)) as T
  }
}
