import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewbox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M5.89308 10.4266C5.92287 9.19239 7.06652 8.375 8.47613 8.375C9.93218 8.375 10.925 9.26006 10.9215 10.3863C10.9245 11.0865 10.527 11.617 9.91774 11.8695C10.26 11.9695 10.5421 12.1358 10.7532 12.3596C11.0383 12.662 11.1849 13.0599 11.1832 13.5138C11.1866 14.7637 9.99832 15.625 8.45548 15.625C6.93177 15.625 5.78233 14.8034 5.74506 13.5574L5.74121 13.4287H7.28269L7.28927 13.5467C7.31477 14.004 7.77137 14.3403 8.45203 14.3403C8.80729 14.3403 9.10213 14.243 9.30473 14.0852C9.50515 13.929 9.61953 13.711 9.61805 13.4513V13.4499C9.61955 13.1821 9.50666 12.957 9.29389 12.7953C9.07804 12.6313 8.75176 12.5276 8.32465 12.5276H7.57652V11.326H8.32465C8.66807 11.326 8.94851 11.2329 9.13983 11.0808C9.32875 10.9306 9.43708 10.7187 9.43559 10.4603L9.43559 10.4588C9.4371 10.2069 9.34261 10.0026 9.18025 9.85979C9.01639 9.71571 8.77406 9.62643 8.46925 9.62643C7.85106 9.62643 7.38915 9.9592 7.37201 10.4342L7.36766 10.5547H5.88999L5.89308 10.4266Z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12.1837 8.46816H14.7462C15.8217 8.46816 16.6999 8.79584 17.3093 9.40916C17.9186 10.0224 18.245 10.907 18.245 11.9933C18.245 13.0829 17.9186 13.9711 17.303 14.5869C16.6873 15.2028 15.797 15.5318 14.698 15.5318H12.1837V8.46816ZM14.6739 9.78612H13.711V14.2139H14.636C15.3499 14.2139 15.8659 14.0252 16.2051 13.6677C16.5455 13.309 16.7281 12.759 16.7281 11.9933C16.7281 11.2311 16.5455 10.6842 16.2096 10.3278C15.8749 9.97283 15.3682 9.78612 14.6739 9.78612Z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}