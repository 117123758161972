import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center space-x-4" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "flex-1 text-sm"
}
const _hoisted_5 = { class: "mt-5 block cursor-pointer" }
const _hoisted_6 = ["accept"]
const _hoisted_7 = {
  key: 1,
  class: "flex-1 text-sm"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-2",
  type: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photograph_icon = _resolveComponent("photograph-icon")!
  const _component_FileSelectorDialog = _resolveComponent("FileSelectorDialog")!
  const _component_ImageEditorDialog = _resolveComponent("ImageEditorDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["border border-gray-200 rounded-md flex items-center justify-center shadow-sm overflow-hidden", [_ctx.width, _ctx.height]]),
        style: _normalizeStyle({ aspectRatio: _ctx.aspectRatio })
      }, [
        (_ctx.state.record && _ctx.state.record.thumbnail)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "w-full h-full",
              src: _ctx.state.record.thumbnail
            }, null, 8, _hoisted_2))
          : (_ctx.state.record && _ctx.state.record.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "w-full h-full",
                src: _ctx.state.record.url
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createBlock(_component_photograph_icon, {
                key: 2,
                class: "w-8 h-8 text-gray-200 mx-auto"
              }))
      ], 6),
      (!_ctx.state.record)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.state.folder && !_ctx.state.loading)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "cursor-pointer button",
                  theme: "secondary",
                  size: "small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.FileSelectorDialogRef.show()))
                }, "Select Existing File"))
              : _createCommentVNode("", true),
            _createElementVNode("label", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("span", {
                class: "button",
                theme: "secondary",
                size: "small",
                "aria-hidden": "true"
              }, "Upload New File", -1)),
              _createElementVNode("input", {
                class: "hidden",
                type: "file",
                style: {"display":"none"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($event.target.value = '')),
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
                accept: _ctx.state.acceptedTypes.join(', ')
              }, null, 40, _hoisted_6)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.allowEditing)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "block",
                  theme: "secondary",
                  size: "small",
                  onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["prevent"]))
                }, "Edit Image"))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "block mt-2",
              theme: "secondary",
              size: "small",
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.reset()), ["prevent"]))
            }, "Replace")
          ]))
    ]),
    (_ctx.state.error)
      ? (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString(_ctx.state.error), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_FileSelectorDialog, {
      ref: "FileSelectorDialogRef",
      folder: _ctx.state.folder,
      onSelect: _ctx.select
    }, null, 8, ["folder", "onSelect"]),
    _createVNode(_component_ImageEditorDialog, {
      ref: "ImageEditorDialogRef",
      "aspect-ratio": _ctx.aspectRatio,
      folder: _ctx.state.folder
    }, null, 8, ["aspect-ratio", "folder"])
  ]))
}