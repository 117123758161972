import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center border-t border-t-100" }
const _hoisted_2 = { class: "flex-1 ml-1 px-6 flex items-center" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_search_icon, { class: "text-gray-500 w-6 h-6" }),
      _createElementVNode("form", {
        class: "flex-1 py-2",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)), ["prevent"]))
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "w-full",
          theme: "search",
          placeholder: _ctx.placeholder,
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.query) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyup && _ctx.keyup(...args)))
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.state.query]
        ])
      ], 32)
    ]),
    _renderSlot(_ctx.$slots, "filters")
  ]))
}