import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    placeholder: "Select Date...",
    value: _ctx.state.formatted,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitUpdate($event.target)))
  }, null, 40, _hoisted_1))
}