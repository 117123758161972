import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "text-sm text-gray-500 px-6 pb-6 overflow-y-auto",
  style: {"max-height":"480px"}
}
const _hoisted_8 = { class: "w-12" }
const _hoisted_9 = { class: "w-8 h-8 bg-black rounded-full flex items-center justify-center relative" }
const _hoisted_10 = { class: "flex-1" }
const _hoisted_11 = { class: "text-gray-900 text-base" }
const _hoisted_12 = { class: "text-gray-700 text-sm" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "text-gray-700 text-xs max-w-md leading-5" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 1,
  class: "mt-4 font-regular text-sm text-copper-700 transition ease-in-out duration-150"
}
const _hoisted_18 = { class: "w-24 text-right" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 1,
  class: "pt-12 pb-4"
}
const _hoisted_21 = {
  key: 1,
  class: "text-sm text-gray-500 p-6 overflow-y-auto"
}
const _hoisted_22 = { class: "flex items-center relative" }
const _hoisted_23 = ["type"]
const _hoisted_24 = { class: "relative flex cursor-pointer items-start space-x-2" }
const _hoisted_25 = { class: "flex items-center h-5" }
const _hoisted_26 = {
  key: 2,
  class: "flex items-center justify-end space-x-4 p-6 border-t border-gray-100"
}
const _hoisted_27 = { class: "flex-1 flex space-x-4 justify-start flex-1 text-left" }
const _hoisted_28 = { class: "flex-1 flex space-x-4 justify-end flex-1 text-right" }
const _hoisted_29 = {
  key: 3,
  class: "flex items-center justify-end space-x-4 p-6 border-t border-gray-100"
}
const _hoisted_30 = { class: "flex-1 flex space-x-4 justify-end flex-1 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_LinkIcon = _resolveComponent("LinkIcon")!
  const _component_LockClosedIcon = _resolveComponent("LockClosedIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_EyeOffIcon = _resolveComponent("EyeOffIcon")!
  const _component_Form = _resolveComponent("Form")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: "max-w-xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, { onClick: _ctx.cancel }, null, 8, ["onClick"]),
        _createVNode(_component_DialogTitle, {
          class: "text-xl font-medium leading-6 line-clamp-2 text-gray-900 capitalize",
          as: "h3"
        }, {
          default: _withCtx(() => [
            (_ctx.state.mode === 'list')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Secure Links"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Create Secure Link"))
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_4, [
          (_ctx.state.mode === 'list')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Share Secure or Private Decks with people outside your company."))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, "Share a secure link with people outside your company."))
        ])
      ]),
      (_ctx.state.mode === 'list')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.state.records.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.state.records, (record, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["flex items-start py-6", [index > 0 ? 'border-t border-gray-100' : '']]),
                    key: record.id
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_LinkIcon, { class: "text-white w-4 h-4" }),
                        (record.has_password)
                          ? (_openBlock(), _createBlock(_component_LockClosedIcon, {
                              key: 0,
                              class: "text-gold-50 absolute -bottom-1 -right-1 w-4 h-4"
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("p", _hoisted_11, _toDisplayString(record.name), 1),
                      _createElementVNode("p", _hoisted_12, [
                        _createElementVNode("span", null, "Created " + _toDisplayString(_ctx.$filters.date(record.created_at, 'MMM dd yyyy')), 1),
                        (record.has_password)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, _cache[12] || (_cache[12] = [
                              _createElementVNode("span", {
                                class: "text-4xl leading-3 relative",
                                style: {"top":"6px"}
                              }, " · ", -1),
                              _createTextVNode("Password Protected")
                            ])))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("p", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.email_addresses, (email, index) => {
                          return (_openBlock(), _createElementBlock("span", { key: email }, [
                            (index > 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, ","))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(` ${email}`), 1)
                          ]))
                        }), 128))
                      ]),
                      (!record.copied)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: "mt-4 font-regular text-sm text-copper-700 transition ease-in-out duration-150 cursor-pointer hover:text-copper-900 focus:outline-none focus:underline",
                            onClick: ($event: any) => (_ctx.copyLink(record))
                          }, "Copy Link", 8, _hoisted_16))
                        : (_openBlock(), _createElementBlock("p", _hoisted_17, "Copied!"))
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("button", {
                        theme: "secondary",
                        size: "small",
                        onClick: ($event: any) => (_ctx.destroy(record))
                      }, [
                        _createVNode(_component_TrashIcon, { class: "text-gray-700 w-4 h-4" })
                      ], 8, _hoisted_19)
                    ])
                  ], 2))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_EmptyState, { label: "secure_links" })
                ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_21, [
            (_ctx.state.error)
              ? (_openBlock(), _createBlock(_component_Alert, {
                  key: 0,
                  class: "mb-4",
                  theme: "danger"
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.state.error), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Form, null, {
              default: _withCtx(() => [
                _createVNode(_component_FormGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormField, {
                      label: "Label",
                      required: "",
                      validator: _ctx.validator.name
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          class: "my-1 w-full",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
                          type: "text",
                          placeholder: "Label",
                          autocomplete: "new-username"
                        }, null, 512), [
                          [_vModelText, _ctx.model.name]
                        ])
                      ]),
                      _: 1
                    }, 8, ["validator"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormField, {
                      label: "Password",
                      info: _ctx.state.showPasswordRules ? 'Please choose a password that is a minimum of 8 characters and contains uppercase and lowercase letters, numbers and special characters.' : null,
                      validator: _ctx.validator.password
                    }, {
                      default: _withCtx(({ hasValidationError }) => [
                        _createElementVNode("div", _hoisted_22, [
                          _withDirectives(_createElementVNode("input", {
                            class: "with--reveal",
                            onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.showPasswordRules = true)),
                            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.showPasswordRules = false)),
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.password) = $event)),
                            type: _ctx.state.showPassword ? 'text' : 'password',
                            placeholder: "Password",
                            autocomplete: "new-password"
                          }, null, 40, _hoisted_23), [
                            [_vModelDynamic, _ctx.model.password]
                          ]),
                          (!_ctx.state.showPassword)
                            ? (_openBlock(), _createBlock(_component_EyeIcon, {
                                key: 0,
                                class: _normalizeClass(["absolute right-0 text-gray-400 h-5 w-5 cursor-pointer transition duration-150 hover:text-gray-900", [hasValidationError ? 'right-6' : '']]),
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state.showPassword = !_ctx.state.showPassword))
                              }, null, 8, ["class"]))
                            : (_openBlock(), _createBlock(_component_EyeOffIcon, {
                                key: 1,
                                class: _normalizeClass(["absolute right-0 text-gray-400 h-5 w-5 cursor-pointer transition duration-150 hover:text-gray-900", [hasValidationError ? 'right-6' : '']]),
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.state.showPassword = !_ctx.state.showPassword))
                              }, null, 8, ["class"]))
                        ])
                      ]),
                      _: 1
                    }, 8, ["info", "validator"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormField, { label: "Recipient Email Addresses" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("textarea", {
                          class: "h-32",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.email_addresses_raw) = $event)),
                          placeholder: "example@domain.com, example2@domain.com",
                          autocomplete: "off"
                        }, null, 512), [
                          [_vModelText, _ctx.model.email_addresses_raw]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _withDirectives(_createElementVNode("input", {
                      class: "h-4 w-4 text-black transition duration-150 ease-in-out rounded",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.notify_recipients) = $event)),
                      type: "checkbox"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.model.notify_recipients]
                    ])
                  ]),
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "ml-3 flex flex-col cursor-pointer" }, [
                    _createElementVNode("label", null, "Notify Recipients"),
                    _createElementVNode("span", { class: "block text-xs max-w-xl leading-5 text-gray-400" }, "A secure link will be emailed to each recipient.")
                  ], -1))
                ])
              ]),
              _: 1
            })
          ])),
      (_ctx.state.mode === 'list')
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("button", {
                theme: "secondary",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.mode('create')))
              }, "New Secure Link")
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("button", {
                theme: "copper",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
              }, "Done")
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("button", {
                theme: "secondary",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.mode('list')))
              }, "Cancel"),
              _createElementVNode("button", {
                theme: "copper",
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.create && _ctx.create(...args)))
              }, "Create Secure Link")
            ])
          ]))
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}