/* eslint-disable */

// This is a stopgap solution to vuelidate 2.0's not return validators
// type. It will allow us to bypass the errors. The passed value does
// fit the interface below, its just very "loosely" enforced.
interface Pagination {
  count: number
  limit: number
  offset: number
  query?: string
  sort_by: string
  includes?: string[]
  archived?: boolean
}

/**
 * This is a componsable mixin function that will allow form components to make use
 * of pagination through table views in a very DRY manner.
 *
 * @param pagination {Pagination} - the pagination model.
 * @param refreshCallback {Function} - the callback for changes.
 *
 * @return {any} - a mix of objects and functions.
 */
export default function (pagination: Pagination, refreshCallback: () => any): any {
  /**
   * Paginate to the next page utilizing the offset and limit.
   *
   * @return {Promise<void>}
   */
  async function next(): Promise<void> {
    pagination.offset += pagination.limit
    if (pagination.offset >= pagination.count) {
      pagination.offset = pagination.count - pagination.limit
    }

    await refreshCallback()
  }

  /**
   * Paginate to the previous page utilizing the offset and limit.
   *
   * @return {Promise<void>}
   */
  async function previous(): Promise<void> {
    pagination.offset -= pagination.limit
    if (pagination.offset <= 1) {
      pagination.offset = 0
    }

    await refreshCallback()
  }

  /**
   * On sorting change, we need to go ahead and make sure that we reset the offset
   * otherwise were going to have massive issues with random data.
   *
   * @return {Promise<void>}
   */
  async function sort(): Promise<void> {
    pagination.offset = 0
    await refreshCallback()
  }

  /**
   * Trigger a query of results, and refresh the result set.
   *
   * @return {Promise<void>}
   */
  async function query(): Promise<void> {
    pagination.offset = 0
    await refreshCallback()
  }

  return {
    next,
    previous,
    sort,
    query
  }
}
