import Store from '@/modules/api/store'
// types & enums
import { State } from '@/modules/api/types'
import { Deck } from '@/modules/api/types/deck'

export default new (class CompanyStore extends Store<State> {
  /** @constructor */
  constructor() {
    super({
      // The current selected deck in the application. This will filter a lot of
      // different methods and endpoints by the company id coming from it.
      selected: null
    })
  }

  /**
   * Set the selected deck state for the application.
   *
   * @param deck {Model}
   */
  public select(deck: Deck) {
    this.state.selected = deck
  }

  /**
   * Resets the state back to the original values. This will allow us to hard
   * reset a store very quickly and will wipe out any state.
   */
  public reset() {
    this.state.selected = null
  }
})()
