import { RouteLocationNormalized } from 'vue-router'

/** @type {string} - the default title for the application */
const base = 'Editor'

/**
 * Set various information for the application based on the route meta. This will be
 * initialized AFTER the routing happens that way any guards that are implemented can
 * be attached first.
 */
export default (to: RouteLocationNormalized): void => {
  document.title = to.meta.title ? `${base} · ${to.meta.title}` : base
}
