import { App } from 'vue'
// stores & services
import CompanyStore from '@/modules/api/stores/company'

/**
 * This exported method will apply the role method through a global directive. This will
 * allow us to prevent access to various items without bloating controller code, and make
 * it all nice and dry.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useCompanyFeatureDirective(app: App): App {
  app.directive('company-feature', {
    /**
     * If the company does not have the feature supplied, then we remove the element
     * so that it cannot be accessed.
     *
     * @note
     * Do *NOT* just hide the element -- you can hack the browser and just un-hide it
     * regardless of permission or role. Make sure to remove the element.
     *
     * @param el {HTMLElement}
     * @param binding {Set}
     */
    mounted(el, binding) {
      if (!CompanyStore.hasFeatureEnabled(binding.value)) {
        el.remove()
      }
    }
  })

  return app
}
