import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { class: "flex items-center justify-end space-x-4 py-6 border-t border-gray-100" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_EmbedCode = _resolveComponent("EmbedCode")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: "max-w-2xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, {
          onClick: _ctx.cancel,
          theme: _ctx.theme
        }, null, 8, ["onClick", "theme"]),
        _createVNode(_component_DialogTitle, {
          class: "line-clamp-2 text-lg font-medium leading-6 text-gray-900",
          as: "h3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title ? _ctx.title : 'Embed Code'), 1)
          ]),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "Copy and paste the following code snippet to desired location.", -1))
      ]),
      _createVNode(_component_EmbedCode, {
        class: "p-6 pb-0",
        noTitle: "",
        noDescription: "",
        "is-partner": _ctx.state.record?.partner,
        url: _ctx.state.record.url_slug,
        onCopied: _ctx.handleCopy
      }, {
        action: _withCtx(({ copied, copyCode }) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              onClick: copyCode,
              theme: "secondary"
            }, _toDisplayString(copied ? 'Copied!' : 'Copy To Clipboard'), 9, _hoisted_3),
            _createElementVNode("button", {
              theme: "copper",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, "Done")
          ])
        ]),
        _: 1
      }, 8, ["is-partner", "url", "onCopied"])
    ]),
    _: 1
  }, 8, ["showing", "onEsc"]))
}