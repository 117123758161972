import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block align-middle relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_up_icon = _resolveComponent("chevron-up-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "px-4 cursor-pointer hover:text-gray-900",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.state.direction)
        ? (_openBlock(), _createBlock(_component_chevron_up_icon, {
            key: 0,
            class: _normalizeClass(["absolute top-0 -right-5 w-4 h-4 inline-block align-middle transition-all duration-200 transform", [_ctx.state.direction === 'desc' ? 'rotate-180' : '', _ctx.isSortedColumn ? 'visible' : 'invisible']])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ])
  ]))
}