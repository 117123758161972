import { createWebHistory, createRouter } from 'vue-router'
import r from '@/modules/application/router/routes'
import afterEach from '@/modules/application/router/hooks/after-each'
import beforeEach from '@/modules/application/router/hooks/before-each'

/**
 * Export the router so that it can be imported in the main.ts bootstrap. This will
 * make all routes accessible.
 *
 * @note The "createWebHistory" requires pushState to work correctly. Please make sure that
 *       when deploying S3/Cloudfront is setup to recognize this functionality.
 */
const router = createRouter({
  history: createWebHistory(),
  routes: r
})

/**
 * Set various information for the application based on the route meta. This will be
 * initialized AFTER the routing happens that way any guards that are implemented can
 * be attached first.
 */
router.afterEach(afterEach)
router.beforeEach(beforeEach)

export default router
