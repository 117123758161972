import { ClientJS } from 'clientjs'

/**
 * Returns whether or not the current browser the user is initializing the application is
 * allowed. The following browsers are allowed:
 *   1. Opera
 *   2. Firefox
 *   3. Chrome
 *   4. Edge
 *
 * @return {boolean}
 */
export function isSupportedBrowser(): boolean {
  const client: any = new ClientJS()
  return client.isFirefox() || client.isChrome() || client.isSafari() || (client.getBrowser() === 'Edge' && client.getEngine() === 'Blink')
}

/**
 * Returns whether or not the current loading instance is mobile. If it is,
 * then we cannot support it and must redirect.
 *
 * @return {boolean}
 */
export function isMobileBrowser(): boolean {
  const client: any = new ClientJS()
  return client.isMobile()
}
