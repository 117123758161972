import { App } from 'vue'
import { Cropper } from 'vue-advanced-cropper'

/**
 * This plugin will globalize the image cropper used throughout the application so that
 * we can not have to bloat our controller code with icon imports.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useImageCropper(app: App): App {
  app.component('Cropper', Cropper)
  return app
}
