/**
 * Pushes the user to login at the webapp with a redirect back to the current page, or specified page.
 * @param redirectPath {string} - the path to redirect back to
 */
export function loginWithRedirect(redirectPath = ''): void {
  let redirectTo = ''
  // If no redirect path, use current location directly
  if (!redirectPath) {
    redirectTo = window.location.href
    // Otherwise, replace the current location pathname with the requested redirect path
  } else {
    redirectTo = window.location.href.replace(window.location.pathname, redirectPath)
  }

  window.location.href = `${process.env.VUE_APP_WEB_APP_URL}/login?redirectedFrom=${redirectTo}`
}

/**
 * Pushes the user back to the webapp.
 */
export function pushToWebApp(path = ''): void {
  window.location.href = `${process.env.VUE_APP_WEB_APP_URL}${path}`
}
