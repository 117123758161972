import { ref, Ref } from 'vue'

// This enum represents the different types of notifications that can be populated
// to the notification portal component. Each different value represents a different
// style of notification.
export enum NotificationTheme {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info'
}

// This is the basic configuration for displaying a notification.
export interface Notification {
  id?: number
  icon?: string
  title?: string
  message?: string
  timeout?: number
  theme?: NotificationTheme
  component?: string
  componentData?: any
}

// Internal list of notifications to display. These will be populated from various
// configurations, and are not all the same.
const $notifications: Ref<Notification[]> = ref([])

export default (): any => {
  /**
   * Push a notification on to the stack of notifications so that it will show up
   * in the actual notification portal.
   *
   * @param notification {Notification} - the configuration for a new notification.
   * @return {void}
   */
  function notify(notification: Notification): void {
    // Assign a random id to the notification so that we can remove it from the
    // queue when required keeping state in sync.
    notification.id = notification.id || Date.now()

    // Assign a timeout for the notification to make sure that we have a default
    // and dont keep them open. We are doing an "in" check because if we do a null
    // check (which is valid) then it will override.
    if (!('timeout' in notification)) {
      notification.timeout = 4 * 1000 // 4 seconds
    }

    // Make sure that we have a theme set. This will allow us to provide a default
    // configuration in the actual display.
    notification.theme = notification.theme || NotificationTheme.Info

    // Set the icon correctly for the type of notification this is.
    if (!('icon' in notification)) {
      switch (notification.theme) {
        case NotificationTheme.Success:
          notification.icon = 'CheckCircleIcon'
          break
        case NotificationTheme.Warning:
          notification.icon = 'InformationCircleIcon'
          break
        case NotificationTheme.Error:
          notification.icon = 'ExclamationCircleIcon'
          break
        default:
          break
      }
    }

    // Add the notification to the stack.
    $notifications.value.push(notification)

    // Issue the timeout request for the notification. We check to see if it has a
    // timeout so that we can allow certain notifications to appear until the user
    // manually closes them.
    if (notification.timeout) {
      setTimeout(() => dismiss(notification), notification.timeout)
    }
  }

  /**
   * Clear the notification from the stack so that the stack does not bubble and
   * maintains a good memory footprint.
   *
   * @param notification {Notification} - the notification to remove.
   * @return {void}
   */
  function dismiss(notification: Notification): void {
    // Find the index of the notification so that we can splice it off the stack. This will
    // alert the notifications queue, and will remove it from displaying.
    const index = $notifications.value.findIndex((n: Notification) => n.id === notification.id)
    if (index < 0) {
      return
    }

    $notifications.value.splice(index, 1)
  }

  /**
   * Clear all notifications from the stack
   */
  function dismissAll(): void {
    $notifications.value.length = 0
  }

  return {
    // data
    $notifications,
    // methods
    notify,
    dismiss,
    dismissAll
  }
}
