import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["theme"]
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_information_circle_icon = _resolveComponent("information-circle-icon")!
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")!
  const _component_exclamation_icon = _resolveComponent("exclamation-icon")!
  const _component_x_circle_icon = _resolveComponent("x-circle-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex items-start",
    type: "alert",
    theme: _ctx.theme
  }, [
    (_ctx.theme === 'info')
      ? (_openBlock(), _createBlock(_component_information_circle_icon, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.theme === 'success')
      ? (_openBlock(), _createBlock(_component_check_circle_icon, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.theme === 'warning')
      ? (_openBlock(), _createBlock(_component_exclamation_icon, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.theme === 'danger')
      ? (_openBlock(), _createBlock(_component_x_circle_icon, { key: 3 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.slots.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title")
          ]))
        : _createCommentVNode("", true),
      (_ctx.slots.content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "content")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}