import { App } from 'vue'
import SessionStore from '@/modules/api/stores/session'

export default function useUserFeatureDirective(app: App): App {
  app.directive('user-feature', {
    async mounted(el, binding) {
      const enabled = await SessionStore.hasFeatureEnabled(binding.value)
      if (!enabled) {
        el.remove()
      }
    }
  })

  return app
}
