import { App } from 'vue'
import Alert from '@/modules/common/components/Alert.vue'
import BaseDialog from '@/modules/common/components/dialogs/Base.vue'
import ConfirmationDialog from '@/modules/common/components/dialogs/Confirmation.vue'
import ManageDialog from '@/modules/common/components/dialogs/ManageDialog.vue'
import AccessDialog from '@/modules/common/components/dialogs/Access.vue'
import SecureLinkDialog from '@/modules/common/components/dialogs/SecureLink.vue'
import EmbedLinkDialog from '@/modules/common/components/dialogs/EmbedLink.vue'
import FileViewerDialog from '@/modules/common/components/dialogs/FileViewer.vue'
import ImageEditorDialog from '@/modules/common/components/dialogs/ImageEditor.vue'
import PreviewDialog from '@/modules/common/components/dialogs/Preview.vue'
import DialogCloseButton from '@/modules/common/components/dialogs/CloseButton.vue'
import SortableColumnHeader from '@/modules/common/components/tables/SortableColumnHeader.vue'
import PaginationFooter from '@/modules/common/components/tables/PaginationFooter.vue'
import SearchBar from '@/modules/common/components/tables/SearchBar.vue'
import EmptyState from '@/modules/common/components/tables/EmptyState.vue'
import TableRow from '@/modules/common/components/tables/TableRow.vue'
import TableColumn from '@/modules/common/components/tables/TableColumn.vue'
import CompanyBreadcrumb from '@/modules/common/components/breadcrumbs/Company.vue'
// icons
import DashboardIcon from '@/modules/common/components/icons/Dashboard.vue'
import ModelIcon from '@/modules/common/components/icons/Model.vue'
import DynamicIcon from '@/modules/common/components/icons/Dynamic.vue'
import ThreeDIcon from '@/modules/common/components/icons/ThreeD.vue'
// footer
import Footer from '@/modules/common/components/Footer.vue'

/**
 * This plugin will globalize the common app components  used throughout the application
 * so that we can not have to bloat our controller code with icon imports.
 *
 * @param app {App} - the vue application wrapper.
 * @return {App}
 */
export default function useCommonComponents(app: App): App {
  // alerts
  app.component('Alert', Alert)
  // dialogs
  app.component('BaseDialog', BaseDialog)
  app.component('DialogCloseButton', DialogCloseButton)
  app.component('ConfirmationDialog', ConfirmationDialog)
  app.component('PreviewDialog', PreviewDialog)
  app.component('EmbedLinkDialog', EmbedLinkDialog)
  app.component('ManageDialog', ManageDialog)
  app.component('FileViewerDialog', FileViewerDialog)
  app.component('AccessDialog', AccessDialog)
  app.component('SecureLinkDialog', SecureLinkDialog)
  app.component('ImageEditorDialog', ImageEditorDialog)
  // tables
  app.component('SortableColumnHeader', SortableColumnHeader)
  app.component('PaginationFooter', PaginationFooter)
  app.component('SearchBar', SearchBar)
  app.component('EmptyState', EmptyState)
  app.component('TableRow', TableRow)
  app.component('TableColumn', TableColumn)
  // breadcrumbs
  app.component('CompanyBreadcrumb', CompanyBreadcrumb)
  // icons
  app.component('DashboardIcon', DashboardIcon)
  app.component('ModelIcon', ModelIcon)
  app.component('DynamicIcon', DynamicIcon)
  app.component('ThreeDIcon', ThreeDIcon)
  // footer
  app.component('Footer', Footer)

  return app
}
