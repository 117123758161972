// stores & services
import Store from '@/modules/api/store'
// types & enums
import { Model, State } from '@/modules/api/types'
import { Permission, Role } from '@/modules/api/enums'
// utilities
import { createAxiosInstance } from '@/modules/api/axios.utilities'
import { isTokenExpired, decodeExpiration } from '@/modules/api/jwt.utilities'
import { loginWithRedirect } from '@/modules/application/router/router.utilities'

/** Create an axios instance that is separate from the regular instance. */
const axios = createAxiosInstance()
axios.defaults.withCredentials = true

export default new (class SessionStore extends Store<State> {
  /** @constructor */
  constructor() {
    super({
      /**
       * The access and refresh tokens are used for api calls, and are not really
       * going to be accessed a whole bunch. We are merely keeping these handy for axios
       * instances.
       *
       * @type {string}
       */
      accessToken: null,
      expiration: null,

      /**
       * The current logged in user.
       * @type {Model}
       */
      user: null,

      /**
       * The current user's request setting object.
       * @type {Model}
       */
      requestSetting: null,

      /**
       * This represents the stack of permissions for the user. This will control
       * access rights, visibility for features and a lot of other things.
       *
       * @type {string[]}
       */
      permissions: [],
      flags: null as string[] | null, // All flags stored in UPPER CASE
      flagQueue: [] as Array<{ feature: string; resolve: (enabled: boolean) => void }>,
      switches: []
    })
  }

  //
  // setters
  //

  /** @param token {string} - the auth token to make requests. */
  public set accessToken(token: string) {
    this.state.accessToken = token
  }

  /** @param expiration {number} - the expiration timestamp for when the session expires. */
  public set expiration(expiration: number) {
    this.state.expiration = expiration
  }

  /** @param user {Model} - the session user. */
  public set user(user: Model) {
    this.state.user = user
    // Ensure user.companies exists
    this.state.user.companies ??= []
  }

  public set userFeatureFlags(flags: string[]) {
    this.state.flags = flags.map(flag => flag.toUpperCase())
    this.state.flagQueue.forEach(({ feature, resolve }: { feature: string; resolve: (enabled: boolean) => void }) =>
      resolve(this.state.flags.includes(feature))
    )
    this.state.flagQueue = []
  }

  public set requestSetting(setting: Model) {
    this.state.requestSetting = setting
  }

  public set switches(switches: string[]) {
    this.state.switches = switches.map(s => s.toUpperCase())
  }

  //
  // methods
  //

  public hasFeatureEnabled(feature: string): Promise<boolean> {
    feature = feature.toUpperCase()
    if (this.state.flags) {
      return Promise.resolve(this.state.flags.includes(feature))
    }

    return new Promise(resolve => {
      this.state.flagQueue.push({ feature, resolve })
    })
  }

  /**
   * Refresh the current session information if the expiration date is coming up, otherwise
   * go ahead and just return, and do nothing. This will make sure that we keep the session
   * active throughout all applications.
   *
   * @param redirectPath {string} - the path to redirect back after login if the initialize fails
   * @return {Promise<void>>}
   */
  public async refresh(redirectPath = ''): Promise<void> {
    // Check the expiration for the session, return if valid
    if (!isTokenExpired(this.$state.accessToken)) {
      return
    }

    try {
      // Were using a new instance of axios directly here to avoid cyclical dependencies in any
      // of the axios implementations or other service implementations.
      const session: Model = (await axios.post('/auth/refresh')).data
      if ('access' in session) {
        // Decode the access token so that we can store the expiration. This will allow us to check
        // the refresh state and expiration state of the access token.
        const expiration = decodeExpiration(session.access)
        // Set the store information
        this.accessToken = session.access
        this.expiration = expiration
      }
    } catch {
      // If refresh errors, user is not logged in, push to login with redirect
      loginWithRedirect(redirectPath)
    }
  }

  /**
   * Based on the internal store state, we need to figure out if the user has the
   * passed role in their roles array (is that role type). This will allow us to set
   * permissions and other things ahead of time.
   *
   * @return {boolean} - whether or not the current session is the role provided.
   */
  public hasRole(role: Role): boolean {
    // If there is no current user, and no roles, then we just return false to
    // prevent random things from happening with regards to application state.
    if (!this.$state.user || !this.$state.user.roles.length) {
      return false
    }

    // This is a convenience feature that allows us to check 2 different
    // roles at once. It will allow us to denote all internal admins together
    // based on their roles.
    if (role === Role.InternalAdmin) {
      return this.hasRole(Role.SuperAdmin) || this.hasRole(Role.AccountLead)
    }

    return this.$state.user.roles.find((r: Model) => [role].includes(r.key)) !== undefined
  }

  /**
   * Add a permission to the stack through the store action.
   *
   * @param permission {Permission} - the permission to add.
   */
  public addPermission(permission: Permission): void {
    this.state.permissions.push(permission)
  }

  /**
   * Based on the internal store state, we need to return a boolean value as to whether or not
   * the user has the supplied permission.
   *
   * @param permission {Permission} - the permissions to check against.
   */
  public hasPermission(permission: Permission): boolean {
    if (permission === Permission.VPEAccess) {
      return this.hasRole(Role.SuperAdmin) || this.hasRole(Role.AccountLead) || this.hasRole(Role.CompanyAdmin)
    }

    // If the current logged in user has super admin access or account lead access, then they
    // are completely untapped in what they are able to do regardless of the passed permission.
    if (this.hasRole(Role.InternalAdmin)) {
      return true
    }

    return this.$state.permissions.includes(permission)
  }

  /**
   * Sees if the current user role is higher than the compared role
   *
   * @return {boolean} - whether or not the current user role is higher than provided
   */
  public isEqualOrHigherRole(roles: { key: Role }[]): boolean {
    if (this.hasRole(Role.SuperAdmin)) return true
    if (this.hasRole(Role.InternalAdmin)) return true
    if (this.hasRole(Role.AccountLead)) return !roles.some(r => r.key === Role.SuperAdmin)
    if (this.hasRole(Role.CompanyAdmin))
      return !roles.some(r => r.key === Role.SuperAdmin || r.key === Role.AccountLead || r.key === Role.InternalAdmin)

    return false
  }
})()
