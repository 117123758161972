import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center space-x-4" }
const _hoisted_2 = { class: "border border-gray-200 rounded-full w-20 h-20 flex items-center justify-center shadow-sm overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "flex-1 text-sm"
}
const _hoisted_5 = { class: "mt-3 inline-block" }
const _hoisted_6 = { class: "cursor-pointer" }
const _hoisted_7 = ["accept"]
const _hoisted_8 = {
  key: 1,
  class: "flex-1 text-sm"
}
const _hoisted_9 = {
  key: 0,
  class: "mt-2",
  type: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photograph_icon = _resolveComponent("photograph-icon")!
  const _component_ImageEditorDialog = _resolveComponent("ImageEditorDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.state.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "w-full h-full",
              src: _ctx.state.imageUrl
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createBlock(_component_photograph_icon, {
              key: 1,
              class: "w-8 h-8 text-gray-200 mx-auto"
            }))
      ]),
      (!_ctx.state.record)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("span", {
                  class: "button",
                  theme: "secondary",
                  size: "small",
                  "aria-hidden": "true"
                }, "Upload New File", -1)),
                _createElementVNode("input", {
                  class: "hidden",
                  type: "file",
                  style: {"display":"none"},
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args))),
                  accept: _ctx.acceptedTypes
                }, null, 40, _hoisted_7)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "block",
              theme: "secondary",
              size: "small",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.edit()), ["prevent"]))
            }, "Edit Image"),
            _createElementVNode("button", {
              class: "block mt-2",
              theme: "secondary",
              size: "small",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.reset()), ["prevent"]))
            }, "Replace")
          ]))
    ]),
    (_ctx.state.error)
      ? (_openBlock(), _createElementBlock("label", _hoisted_9, _toDisplayString(_ctx.state.error), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ImageEditorDialog, {
      ref: "ImageEditorDialogRef",
      onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reset()))
    }, null, 512)
  ]))
}